var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal custom-modal fade",
        attrs: { id: "add_note_modal", role: "dialog" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submitNote.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.customization.notes.name"))
                        ),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("*")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.name,
                            expression: "formData.name"
                          }
                        ],
                        ref: "name",
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.formData.name.$error },
                        attrs: { type: "text" },
                        domProps: { value: _vm.formData.name },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "name",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.$v.formData.name.$touch()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.name.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.nameError))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.customization.notes.type"))
                        ),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("*")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.noteType,
                              expression: "noteType"
                            }
                          ],
                          staticClass: "select form-control",
                          class: { "is-invalid": _vm.$v.noteType.$error },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.noteType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.$v.noteType.$touch()
                              }
                            ]
                          }
                        },
                        [
                          _c("option", [_vm._v("Select")]),
                          _vm._v(" "),
                          _vm._l(_vm.types, function(item, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: item } },
                              [_vm._v(_vm._s(item))]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.$v.noteType.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.typeError))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.customization.notes.notes"))
                        ),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("*")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.notes,
                            expression: "formData.notes"
                          }
                        ],
                        staticClass: "form-control",
                        domProps: { value: _vm.formData.notes },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.formData, "notes", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "submit-section" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-light submit-btn",
                          on: { click: _vm.closeNoteModal }
                        },
                        [
                          _c("i", { staticClass: "fas fa-window-close" }),
                          _vm._v("\n                          Cancel")
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "submit-section" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary submit-btn",
                          attrs: { disabled: _vm.isLoading }
                        },
                        [
                          _vm.isLoading
                            ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
                            : _vm._e(),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                !_vm.isEdit
                                  ? _vm.$t("general.save")
                                  : _vm.$t("general.update")
                              )
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("button", {
      staticClass: "btn btn-primary d-none",
      attrs: {
        id: "openmodal",
        "data-toggle": "modal",
        "data-target": "#add_note_modal"
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Add Note")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            id: "cancelbutton",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }