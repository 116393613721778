var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "text-right" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-outline-primary",
            on: { click: _vm.addItemUnit }
          },
          [_vm._v(_vm._s(_vm.$t("settings.customization.items.add_item_unit")))]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("vuetable", {
              ref: "vuetable",
              attrs: {
                "api-mode": false,
                fields: _vm.fields,
                "per-page": _vm.perPage,
                "pagination-path": "pagination",
                "sort-order": _vm.sortOrder,
                "no-data-template": _vm.showNomessage
                  ? "No records available"
                  : "",
                "data-manager": _vm.dataManager,
                css: _vm.css.table
              },
              on: { "vuetable:pagination-data": _vm.onPaginationData },
              scopedSlots: _vm._u(
                [
                  {
                    key: "name",
                    fn: function(props) {
                      return [
                        _vm._v(
                          "\n                        " +
                            _vm._s(props.rowData.name) +
                            "\n                  "
                        )
                      ]
                    }
                  },
                  {
                    key: _vm.$t("settings.tax_types.action"),
                    fn: function(props) {
                      return [
                        _c("div", { staticClass: "table-button-container" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-sm btn-white text-success mr-2",
                              on: {
                                click: function($event) {
                                  return _vm.editItemUnit(props.rowData)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-edit mr-1" }),
                              _vm._v(" " + _vm._s(_vm.$t("general.edit")))
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-sm btn-white text-danger mr-2",
                              on: {
                                click: function($event) {
                                  return _vm.removeItemUnit(props.rowData.id)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "far fa-trash-alt mr-1" }),
                              _vm._v(_vm._s(_vm.$t("general.delete")))
                            ]
                          )
                        ])
                      ]
                    }
                  }
                ],
                null,
                true
              )
            }),
            _vm._v(" "),
            _c("vuetable-pagination", {
              ref: "pagination",
              attrs: { css: _vm.css.pagination },
              on: { "vuetable-pagination:change-page": _vm.onChangePage }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("ItemUnitModal", { ref: "modal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }