var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", { staticClass: "customer-create" }, [
    _c("div", { staticClass: "content container-fluid" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$tc("customers.customer", 2)))
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                    _vm._v(_vm._s(_vm.$tc("customers.title")))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c("router-link", { attrs: { to: "/admin/customers" } }, [
                    _vm._v(_vm._s(_vm.$tc("customers.customer", 2)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _vm.$route.name === "customers.edit"
                ? _c(
                    "li",
                    { staticClass: "breadcrumb-item active" },
                    [
                      _c("router-link", { attrs: { to: "/admin/customers" } }, [
                        _vm._v(_vm._s(_vm.$t("customers.edit_customer")))
                      ])
                    ],
                    1
                  )
                : _c("li", { staticClass: "breadcrumb-item active" }, [
                    _vm._v(_vm._s(_vm.$tc("customers.new_customer")))
                  ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.$t("customers.basic_info")))
                ]),
                _vm._v(" "),
                !_vm.initLoad
                  ? _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submitCustomerData.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "col-sm-3 col-form-label input-label",
                              attrs: { for: "name" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$tc(
                                    "settings.account_settings.profile_picture"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _c("div", [
                              !_vm.previewAvatar
                                ? _c("input", {
                                    attrs: { type: "file", id: "fileinput" },
                                    on: {
                                      change: _vm.onFileChange,
                                      click: function($event) {
                                        return _vm.clearValue(1)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm.previewAvatar
                                ? _c("img", {
                                    staticClass: "avatar-img",
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      src: _vm.previewAvatar,
                                      id: "previewImg"
                                    },
                                    on: {
                                      click: _vm.changeFileImage,
                                      error: _vm.altImg
                                    }
                                  })
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "d-none",
                              attrs: { type: "file", id: "fileimageinput" },
                              on: {
                                change: _vm.onFileChange,
                                click: function($event) {
                                  return _vm.clearValue(2)
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("customers.display_name")) + " "
                                ),
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData.name,
                                    expression: "formData.name"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.formData.name.$error
                                },
                                attrs: { type: "text" },
                                domProps: { value: _vm.formData.name },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.formData,
                                        "name",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      return _vm.$v.formData.name.$touch()
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.formData.name.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(_vm.displayNameError))]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.email")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData.email,
                                    expression: "formData.email"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.formData.email.$error
                                },
                                attrs: { type: "email" },
                                domProps: { value: _vm.formData.email },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.formData,
                                        "email",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      return _vm.$v.formData.email.$touch()
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.formData.email.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(_vm.emailError))]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("customers.primary_currency"))
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.currency,
                                      expression: "currency"
                                    }
                                  ],
                                  staticClass: "select form-control",
                                  class: {
                                    "is-invalid": _vm.$v.currency.$error
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.currency = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c("option", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customers.select_currency")
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.currencies, function(item) {
                                    return _c(
                                      "option",
                                      {
                                        key: item.id,
                                        domProps: { value: item.id }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.$v.currency.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v("Currency required")]
                                  )
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("customers.primary_contact_name")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.formData.contact_name,
                                    expression: "formData.contact_name",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.formData.contact_name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formData,
                                      "contact_name",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.phone")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.formData.phone,
                                    expression: "formData.phone",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.formData.phone.$error
                                },
                                attrs: {
                                  type: "text",
                                  v: _vm.$v.formData.phone
                                },
                                domProps: { value: _vm.formData.phone },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.formData,
                                        "phone",
                                        $event.target.value.trim()
                                      )
                                    },
                                    function($event) {
                                      return _vm.$v.formData.phone.$touch()
                                    }
                                  ],
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.formData.phone.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(_vm.phoneError))]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.website")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData.website,
                                    expression: "formData.website"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.formData.website.$error
                                },
                                attrs: { type: "text" },
                                domProps: { value: _vm.formData.website },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.formData,
                                        "website",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      return _vm.$v.formData.website.$touch()
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.formData.website.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(_vm.urlError))]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("h4", { staticClass: "card-title mt-4" }, [
                          _vm._v(_vm._s(_vm.$t("customers.billing_address")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.name")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.billing.name,
                                    expression: "billing.name",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.billing.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.billing,
                                      "name",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.state")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.billing.state,
                                    expression: "billing.state"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.billing.state },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.billing,
                                      "state",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.address")))
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.billing.address_street_1,
                                    expression: "billing.address_street_1",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.billing.address_street_1.$error
                                },
                                attrs: {
                                  rows: "3",
                                  cols: "5",
                                  placeholder: _vm.$t("general.street_1")
                                },
                                domProps: {
                                  value: _vm.billing.address_street_1
                                },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.billing,
                                        "address_street_1",
                                        $event.target.value.trim()
                                      )
                                    },
                                    function($event) {
                                      return _vm.$v.billing.address_street_1.$touch()
                                    }
                                  ],
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.billing.address_street_1.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(_vm.billAddress1Error))]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.billing.address_street_2,
                                    expression: "billing.address_street_2",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.billing.address_street_2.$error
                                },
                                attrs: {
                                  rows: "3",
                                  cols: "5",
                                  placeholder: _vm.$t("general.street_2")
                                },
                                domProps: {
                                  value: _vm.billing.address_street_2
                                },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.billing,
                                        "address_street_2",
                                        $event.target.value.trim()
                                      )
                                    },
                                    function($event) {
                                      return _vm.$v.billing.address_street_2.$touch()
                                    }
                                  ],
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.billing.address_street_2.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(_vm.billAddress2Error))]
                                  )
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.country")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.billing_country,
                                      expression: "billing_country"
                                    }
                                  ],
                                  staticClass: "select form-control",
                                  attrs: { id: "billing_country" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.billing_country = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c("option", [
                                    _vm._v(
                                      _vm._s(_vm.$t("general.select_country"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.countries, function(item) {
                                    return _c(
                                      "option",
                                      {
                                        key: item.id,
                                        domProps: { value: item.id }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  })
                                ],
                                2
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.city")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.billing.city,
                                    expression: "billing.city"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.billing.city },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.billing,
                                      "city",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.phone")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.billing.phone,
                                    expression: "billing.phone",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.billing.phone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.billing,
                                      "phone",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.zip_code")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.billing.zip,
                                    expression: "billing.zip",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.billing.zip },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.billing,
                                      "zip",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("h4", { staticClass: "card-title mt-4" }, [
                          _vm._v(_vm._s(_vm.$t("customers.shipping_address")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-right" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-outline-primary btn-sm",
                              on: {
                                click: function($event) {
                                  return _vm.copyAddress(true)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("customers.copy_billing_address"))
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.name")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.shipping.name,
                                    expression: "shipping.name",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.shipping.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.shipping,
                                      "name",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.state")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.shipping.state,
                                    expression: "shipping.state",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.shipping.state },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.shipping,
                                      "state",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.address")))
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.shipping.address_street_1,
                                    expression: "shipping.address_street_1",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  rows: "3",
                                  cols: "5",
                                  placeholder: _vm.$t("general.street_1")
                                },
                                domProps: {
                                  value: _vm.shipping.address_street_1
                                },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shipping,
                                        "address_street_1",
                                        $event.target.value.trim()
                                      )
                                    },
                                    function($event) {
                                      return _vm.$v.shipping.address_street_1.$touch()
                                    }
                                  ],
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.shipping.address_street_1.$error
                                ? _c("div", [
                                    !_vm.$v.shipping.address_street_1.maxLength
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "text-sm text-danger"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "validation.address_maxlength"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.shipping.address_street_2,
                                    expression: "shipping.address_street_2",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  rows: "3",
                                  cols: "5",
                                  placeholder: _vm.$t("general.street_2")
                                },
                                domProps: {
                                  value: _vm.shipping.address_street_2
                                },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.shipping,
                                        "address_street_2",
                                        $event.target.value.trim()
                                      )
                                    },
                                    function($event) {
                                      return _vm.$v.shipping.address_street_2.$touch()
                                    }
                                  ],
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.shipping.address_street_2.$error
                                ? _c("div", [
                                    !_vm.$v.shipping.address_street_2.maxLength
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "text-sm text-danger"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "validation.address_maxlength"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.country")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shipping_country,
                                      expression: "shipping_country"
                                    }
                                  ],
                                  staticClass: "select form-control",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.shipping_country = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c("option", [
                                    _vm._v(
                                      _vm._s(_vm.$t("general.select_country"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.countries, function(item) {
                                    return _c(
                                      "option",
                                      {
                                        key: item.id,
                                        domProps: { value: item.id }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  })
                                ],
                                2
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.city")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.shipping.city,
                                    expression: "shipping.city",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.shipping.city },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.shipping,
                                      "city",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.phone")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.shipping.phone,
                                    expression: "shipping.phone",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.shipping.phone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.shipping,
                                      "phone",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("customers.zip_code")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.shipping.zip,
                                    expression: "shipping.zip",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.shipping.zip },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.shipping,
                                      "zip",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-right mt-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "submit",
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading
                              }
                            },
                            [
                              _vm.isLoading
                                ? _c("i", {
                                    staticClass: "fas fa-spinner fa-spin"
                                  })
                                : _vm._e(),
                              _vm._v(_vm._s(_vm.$t("customers.save_customer")))
                            ]
                          )
                        ])
                      ]
                    )
                  : _c("base-loader")
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal custom-modal fade",
                attrs: { id: "crop_image_form", role: "dialog" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-dialog modal-dialog-centered",
                    attrs: { role: "document" }
                  },
                  [
                    _c("div", { staticClass: "modal-content" }, [
                      _c("div", { staticClass: "modal-header" }, [
                        _c("h5", { staticClass: "modal-title" }, [
                          _vm._v("Crop Image")
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: {
                              type: "button",
                              "data-dismiss": "modal",
                              id: "cancelbutton",
                              "aria-label": "Close"
                            }
                          },
                          [
                            _c("span", { attrs: { "aria-hidden": "true" } }, [
                              _vm._v("×")
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "modal-body" },
                        [
                          _c("Cropper", {
                            ref: "cropper",
                            staticClass: "cropper",
                            attrs: {
                              src: _vm.previewImage,
                              "stencil-props": {
                                aspectRatio: 1
                              },
                              canvas: {
                                height: 400,
                                width: 400
                              }
                            },
                            on: { change: _vm.changeImage }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "submit-section" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary submit-btn",
                                attrs: { type: "button" },
                                on: { click: _vm.cancelpopup }
                              },
                              [
                                _vm._v(
                                  " \n                            " +
                                    _vm._s(_vm.$t("general.cancel"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary submit-btn",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.isLoading
                                },
                                on: { click: _vm.cropImage }
                              },
                              [
                                _vm.isLoading
                                  ? _c("i", {
                                      staticClass: "fas fa-spinner fa-spin"
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$t("general.save"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "d-none",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "modal",
                                  id: "cancelpopup",
                                  "data-dismiss": "modal"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$t("general.save"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "d-none",
                                attrs: {
                                  "data-toggle": "modal",
                                  id: "showpopup",
                                  "data-target": "#crop_image_form"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$t("general.save"))
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }