var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", [
    _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(_vm._s(_vm.$t("navigation.settings")))
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                  _vm._v(_vm._s(_vm.$t("general.home")))
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item active" }, [
              _vm._v(_vm._s(_vm.$t("navigation.settings")))
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-3 col-md-4" }, [
        _c("div", { staticClass: "widget settings-menu" }, [
          _c("ul", [
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/user-profile" }
                  },
                  [
                    _c("i", { staticClass: "far fa-user" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.account_settings.account_settings")
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/company-info" }
                  },
                  [
                    _c("i", { staticClass: "far fa-list-alt" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.menu_title.company_information")
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/customization" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-edit" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.customization"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/preferences" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-cog" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.menu_title.preferences")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/notifications" }
                  },
                  [
                    _c("i", { staticClass: "far fa-bell" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.notifications"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/tax-types" }
                  },
                  [
                    _c("i", { staticClass: "far fa-check-square" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.menu_title.tax_types")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/payment-mode" }
                  },
                  [
                    _c("i", { staticClass: "far fa-credit-card" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.payment_modes"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/custom-fields" }
                  },
                  [
                    _c("i", { staticClass: "far fa-bell" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.custom_fields"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/expense-category" }
                  },
                  [
                    _c("i", { staticClass: "far fa-list-alt" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.expense_category"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/mail-configuration" }
                  },
                  [
                    _c("i", { staticClass: "far fa-check-square" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.mail.mail_config")))
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-9 col-md-8 mt-3" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v(
                _vm._s(_vm.$t("settings.account_settings.account_settings"))
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.account_settings.section_description")
                  )
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.updateUserData.apply(null, arguments)
                  }
                }
              },
              [
                _vm.isRequestOnGoing
                  ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "name" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$tc("settings.account_settings.profile_picture")
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("div", [
                      !_vm.previewAvatar
                        ? _c("input", {
                            attrs: { type: "file", id: "fileinput" },
                            on: {
                              change: _vm.onFileChange,
                              click: function($event) {
                                return _vm.clearValue(1)
                              }
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm.previewAvatar
                        ? _c("img", {
                            staticClass: "avatar-img",
                            staticStyle: { width: "100px" },
                            attrs: { src: _vm.previewAvatar, id: "previewImg" },
                            on: {
                              click: _vm.changeFileImage,
                              error: _vm.altImg
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "d-none",
                      attrs: { type: "file", id: "fileimageinput" },
                      on: {
                        change: _vm.onFileChange,
                        click: function($event) {
                          return _vm.clearValue(2)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "name" }
                    },
                    [_vm._v(_vm._s(_vm.$tc("settings.account_settings.name")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.name,
                          expression: "formData.name"
                        }
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.formData.name.$error },
                      attrs: {
                        type: "text",
                        id: "name",
                        placeholder: "Your Name"
                      },
                      domProps: { value: _vm.formData.name },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.formData, "name", $event.target.value)
                          },
                          function($event) {
                            return _vm.$v.formData.name.$touch()
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.name.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(_vm._s(_vm.nameError))
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "email" }
                    },
                    [_vm._v(_vm._s(_vm.$tc("settings.account_settings.email")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.email,
                          expression: "formData.email"
                        }
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.formData.email.$error },
                      attrs: {
                        type: "email",
                        id: "email",
                        placeholder: _vm.$t("settings.user_profile.email")
                      },
                      domProps: { value: _vm.formData.email },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.formData, "email", $event.target.value)
                          },
                          function($event) {
                            return _vm.$v.formData.email.$touch()
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.email.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(_vm._s(_vm.emailError))
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "phone" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$tc("settings.account_settings.password"))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.password,
                          expression: "formData.password"
                        }
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.formData.password.$error },
                      attrs: {
                        type: "text",
                        id: "password",
                        placeholder: "**********"
                      },
                      domProps: { value: _vm.formData.password },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formData,
                              "password",
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.$v.formData.password.$touch()
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.password.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(_vm._s(_vm.passwordError))
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "phone" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$tc("settings.account_settings.confirm_password")
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.confirm_password,
                          expression: "formData.confirm_password"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.$v.formData.confirm_password.$error
                      },
                      attrs: {
                        type: "text",
                        id: "confirm_password",
                        placeholder: "***********"
                      },
                      domProps: { value: _vm.formData.confirm_password },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formData,
                              "confirm_password",
                              $event.target.value
                            )
                          },
                          function($event) {
                            return _vm.$v.formData.confirm_password.$touch()
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.confirm_password.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(_vm._s(_vm.confirmPasswordError))
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                    _vm._v(_vm._s(_vm.$tc("settings.language")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-9" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.language,
                            expression: "language"
                          }
                        ],
                        staticClass: "select form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.language = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", [
                          _vm._v(
                            _vm._s(
                              _vm.$tc("settings.preferences.select_language")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.languages, function(item) {
                          return _c(
                            "option",
                            { key: item.code, domProps: { value: item.code } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-right" }, [
                  !_vm.isLoading
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$tc("settings.account_settings.save"))
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal custom-modal fade",
          attrs: { id: "crop_image_form", role: "dialog" }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v("Crop Image")
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        id: "cancelbutton",
                        "aria-label": "Close"
                      }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("×")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _c("Cropper", {
                      ref: "cropper",
                      staticClass: "cropper",
                      attrs: {
                        src: _vm.previewImage,
                        "stencil-props": {
                          aspectRatio: 1
                        },
                        canvas: {
                          height: 400,
                          width: 400
                        }
                      },
                      on: { change: _vm.changeImage }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "submit-section" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary submit-btn",
                          on: { click: _vm.cancelpopup }
                        },
                        [
                          _vm._v(
                            " \n                            " +
                              _vm._s(_vm.$t("general.cancel"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary submit-btn",
                          attrs: { disabled: _vm.isLoading },
                          on: { click: _vm.cropImage }
                        },
                        [
                          _vm.isLoading
                            ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
                            : _vm._e(),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("general.save"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "d-none",
                          attrs: {
                            "data-toggle": "modal",
                            id: "cancelpopup",
                            "data-dismiss": "modal"
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("general.save"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "d-none",
                          attrs: {
                            "data-toggle": "modal",
                            id: "showpopup",
                            "data-target": "#crop_image_form"
                          }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("general.save"))
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }