var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v(_vm._s(_vm.$t("wizard.permissions.permissions")))]),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.$t("wizard.permissions.permission_desc")))]),
      _vm._v(" "),
      _vm.isFetching
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative mt-3" },
        [
          _vm._l(_vm.permissions, function(permission, index) {
            return _c("div", { key: index, staticClass: "border-gray-200" }, [
              _c("div", { staticClass: "form-group permission" }, [
                _c("div", { staticClass: "col-span-2 p-3" }, [
                  _vm._v(
                    "\n          " + _vm._s(permission.folder) + "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "p-3 text-right" }, [
                  permission.isSet ? _c("span") : _c("span"),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(permission.permission))])
                ])
              ])
            ])
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFetching,
                  expression: "!isFetching"
                }
              ],
              staticClass: "btn btn-primary mt-10",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary"
              },
              on: { click: _vm.next }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("wizard.continue")) + "\n    ")]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }