var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-body" }, [
    _c(
      "form",
      {
        staticClass: "mt-6",
        attrs: { action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.updateInvoiceSetting.apply(null, arguments)
          }
        }
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [
            _vm._v(
              _vm._s(_vm.$t("settings.customization.invoices.invoice_prefix"))
            )
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.invoices.invoice_prefix,
                expression: "invoices.invoice_prefix"
              }
            ],
            staticClass: "form-control",
            staticStyle: { "max-width": "30%" },
            attrs: {
              type: "text",
              invalid: _vm.$v.invoices.invoice_prefix.$error
            },
            domProps: { value: _vm.invoices.invoice_prefix },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.invoices, "invoice_prefix", $event.target.value)
                },
                function($event) {
                  return _vm.$v.invoices.invoice_prefix.$touch()
                }
              ],
              keyup: function($event) {
                return _vm.changeToUppercase("INVOICES")
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "settings.customization.invoices.default_invoice_email_body"
                  )
                )
              )
            ]),
            _vm._v(" "),
            _c("vue-editor", {
              model: {
                value: _vm.invoices.invoice_mail_body,
                callback: function($$v) {
                  _vm.$set(_vm.invoices, "invoice_mail_body", $$v)
                },
                expression: "invoices.invoice_mail_body"
              }
            }),
            _vm._v(" "),
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.InvoiceMailFields },
              model: {
                value: _vm.invoices.invoice_mail_body,
                callback: function($$v) {
                  _vm.$set(_vm.invoices, "invoice_mail_body", $$v)
                },
                expression: "invoices.invoice_mail_body"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "settings.customization.invoices.company_address_format"
                  )
                )
              )
            ]),
            _vm._v(" "),
            _c("vue-editor", {
              model: {
                value: _vm.invoices.company_address_format,
                callback: function($$v) {
                  _vm.$set(_vm.invoices, "company_address_format", $$v)
                },
                expression: "invoices.company_address_format"
              }
            }),
            _vm._v(" "),
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.companyFields },
              model: {
                value: _vm.invoices.company_address_format,
                callback: function($$v) {
                  _vm.$set(_vm.invoices, "company_address_format", $$v)
                },
                expression: "invoices.company_address_format"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "settings.customization.invoices.shipping_address_format"
                  )
                )
              )
            ]),
            _vm._v(" "),
            _c("vue-editor", {
              model: {
                value: _vm.invoices.shipping_address_format,
                callback: function($$v) {
                  _vm.$set(_vm.invoices, "shipping_address_format", $$v)
                },
                expression: "invoices.shipping_address_format"
              }
            }),
            _vm._v(" "),
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.shippingFields },
              model: {
                value: _vm.invoices.shipping_address_format,
                callback: function($$v) {
                  _vm.$set(_vm.invoices, "shipping_address_format", $$v)
                },
                expression: "invoices.shipping_address_format"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "settings.customization.invoices.billing_address_format"
                  )
                )
              )
            ]),
            _vm._v(" "),
            _c("vue-editor", {
              model: {
                value: _vm.invoices.billing_address_format,
                callback: function($$v) {
                  _vm.$set(_vm.invoices, "billing_address_format", $$v)
                },
                expression: "invoices.billing_address_format"
              }
            }),
            _vm._v(" "),
            _c("base-custom-input", {
              staticClass: "mt-2",
              attrs: { fields: _vm.billingFields },
              model: {
                value: _vm.invoices.billing_address_format,
                callback: function($$v) {
                  _vm.$set(_vm.invoices, "billing_address_format", $$v)
                },
                expression: "invoices.billing_address_format"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary mt-4",
            attrs: { disabled: _vm.isLoading, type: "submit" }
          },
          [
            _vm.isLoading
              ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
              : _vm._e(),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("settings.customization.save")) +
                "\n    "
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }