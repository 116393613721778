var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", [
    _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("h3", { staticClass: "page-title" }, [_vm._v("Settings")]),
          _vm._v(" "),
          _c("ul", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                  _vm._v("Dashboard")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item active" }, [
              _vm._v("Profile Settings")
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-xl-3 col-md-4" }, [
          _c("div", { staticClass: "widget settings-menu" }, [
            _c("ul", [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/user-profile" }
                    },
                    [
                      _c("i", { staticClass: "far fa-user" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Account Settings")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/company-info" }
                    },
                    [
                      _c("i", { staticClass: "far fa-list-alt" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Company Information")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/customization" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-edit" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.customization"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/preferences" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-cog" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Preferences")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/notifications" }
                    },
                    [
                      _c("i", { staticClass: "far fa-bell" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Notifications")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/tax-types" }
                    },
                    [
                      _c("i", { staticClass: "far fa-check-square" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Tax Types")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/payment-mode" }
                    },
                    [
                      _c("i", { staticClass: "far fa-bell" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Payment Modes")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/custom-fields" }
                    },
                    [
                      _c("i", { staticClass: "far fa-bell" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Custom Fields")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/notes" }
                    },
                    [
                      _c("i", { staticClass: "far fa-check-square" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Notes")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/expense-category" }
                    },
                    [
                      _c("i", { staticClass: "far fa-list-alt" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Expense Categories")])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/mail-configuration" }
                    },
                    [
                      _c("i", { staticClass: "far fa-check-square" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Mail Configuration")])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-9 col-md-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("div", { staticClass: "page-header" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h5", { staticClass: "card-title" }, [_vm._v("Notes")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Save time by creating notes and reusing them on your invoices, estimates & payments."
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-auto" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-primary btn-sm",
                        on: { click: _vm.openNoteSelectModal }
                      },
                      [_vm._v("Add Note")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("NoteModal", { ref: "modal" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }