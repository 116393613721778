var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", [
    _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("h3", { staticClass: "page-title" }, [_vm._v("Settings")]),
          _vm._v(" "),
          _c("ul", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                  _vm._v("Dashboard")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item active" }, [
              _vm._v("Profile Settings")
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-3 col-md-4" }, [
        _c("div", { staticClass: "widget settings-menu" }, [
          _c("ul", [
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/user-profile" }
                  },
                  [
                    _c("i", { staticClass: "far fa-user" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.account_settings.account_settings")
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/company-info" }
                  },
                  [
                    _c("i", { staticClass: "far fa-list-alt" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.menu_title.company_information")
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/customization" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-edit" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.customization"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/preferences" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-cog" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.menu_title.preferences")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/notifications" }
                  },
                  [
                    _c("i", { staticClass: "far fa-bell" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.notifications"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/tax-types" }
                  },
                  [
                    _c("i", { staticClass: "far fa-check-square" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.menu_title.tax_types")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/payment-mode" }
                  },
                  [
                    _c("i", { staticClass: "far fa-credit-card" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.payment_modes"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/custom-fields" }
                  },
                  [
                    _c("i", { staticClass: "far fa-bell" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.custom_fields"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/expense-category" }
                  },
                  [
                    _c("i", { staticClass: "far fa-list-alt" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.expense_category"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/mail-configuration" }
                  },
                  [
                    _c("i", { staticClass: "far fa-check-square" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.mail.mail_config")))
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-9 col-md-8 mt-3" },
        [
          _vm.isRequestOnGoing
            ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h5", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.$t("settings.mail.mail_config")))
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("settings.mail.mail_config_desc")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _vm.mailConfigData
                ? _c(
                    "div",
                    [
                      _c(
                        _vm.mail_driver,
                        {
                          tag: "component",
                          attrs: {
                            "config-data": _vm.mailConfigData,
                            loading: _vm.isLoading,
                            "mail-drivers": _vm.mail_drivers
                          },
                          on: {
                            "on-change-driver": function(val) {
                              return (_vm.mail_driver = _vm.mailConfigData.mail_driver = val)
                            },
                            "submit-data": _vm.saveEmailConfig
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "ml-2 btn btn-primary",
                              attrs: {
                                variant: "primary-outline",
                                type: "button"
                              },
                              on: { click: _vm.openMailTestModal }
                            },
                            [
                              _vm._v(
                                "\n                         " +
                                  _vm._s(_vm.$t("general.test_mail_conf")) +
                                  "\n                       "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("MailTestModal", { ref: "modal" })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }