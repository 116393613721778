var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content container-fluid" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("estimates.title")))
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                    _vm._v(_vm._s(_vm.$t("general.home")))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c("router-link", { attrs: { to: "/admin/estimates" } }, [
                    _vm._v(_vm._s(_vm.$tc("estimates.estimate", 2)))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _vm.$route.name === "estimates.edit"
                ? _c("li", { staticClass: "breadcrumb-item active" }, [
                    _vm._v(_vm._s(_vm.$t("estimates.edit_estimate")))
                  ])
                : _c("li", { staticClass: "breadcrumb-item active" }, [
                    _vm._v(_vm._s(_vm.$t("estimates.new_estimate")))
                  ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm.isLoadingEstimate
            ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                !_vm.isLoadingEstimate && !_vm.isLoadingData
                  ? _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submitForm.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("payments.customer"))),
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedCustomer,
                                      expression: "selectedCustomer"
                                    }
                                  ],
                                  staticClass: "select form-control",
                                  class: {
                                    "is-invalid": _vm.$v.selectedCustomer.$error
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.selectedCustomer = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function($event) {
                                        return _vm.$v.selectedCustomer.$touch()
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("option", [_vm._v("Select Customer")]),
                                  _vm._v(" "),
                                  _vm._l(_vm.customers, function(item) {
                                    return _c(
                                      "option",
                                      {
                                        key: item.id,
                                        domProps: { value: item.id }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.$v.selectedCustomer.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(_vm.estimateDateError))]
                                  )
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("reports.estimates.estimate_date")
                                  ) + " "
                                ),
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "datemx" },
                                [
                                  _c("datepicker", {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.newEstimate.estimate_date.$error
                                    },
                                    attrs: {
                                      editable: false,
                                      clearable: false,
                                      "disabled-date":
                                        _vm.$utils.disabledAfterToday,
                                      "value-type": "format",
                                      format: "YYYY-MM-DD"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.$v.newEstimate.estimate_date.$touch()
                                      }
                                    },
                                    model: {
                                      value: _vm.newEstimate.estimate_date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.newEstimate,
                                          "estimate_date",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "newEstimate.estimate_date"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.$v.newEstimate.estimate_date.$error
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [_vm._v(_vm._s(_vm.estimateDateError))]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("reports.estimates.due_date")) +
                                    " "
                                ),
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "datemx" },
                                [
                                  _c("datepicker", {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.newEstimate.expiry_date.$error
                                    },
                                    attrs: {
                                      editable: false,
                                      clearable: false,
                                      "value-type": "format",
                                      format: "YYYY-MM-DD"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.$v.newEstimate.expiry_date.$touch()
                                      }
                                    },
                                    model: {
                                      value: _vm.newEstimate.expiry_date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.newEstimate,
                                          "expiry_date",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "newEstimate.expiry_date"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.$v.newEstimate.expiry_date.$error
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [_vm._v(_vm._s(_vm.estimateDateError))]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-4 mt-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("estimates.estimate_number")) +
                                    " "
                                ),
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.estimateNumAttribute,
                                    expression: "estimateNumAttribute"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.estimateNumAttribute.$error
                                },
                                attrs: { type: "text" },
                                domProps: { value: _vm.estimateNumAttribute },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.estimateNumAttribute =
                                        $event.target.value
                                    },
                                    function($event) {
                                      return _vm.$v.estimateNumAttribute.$touch()
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.estimateNumAttribute.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(_vm.estimateNumError))]
                                  )
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-4 mt-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("estimates.ref_number")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newEstimate.reference_number,
                                    expression: "newEstimate.reference_number"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.newEstimate.reference_number.$error
                                },
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.newEstimate.reference_number
                                },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.newEstimate,
                                        "reference_number",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      return _vm.$v.newEstimate.reference_number.$touch()
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _vm.$v.newEstimate.reference_number.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(_vm.referenceNumError))]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-responsive mt-4" }, [
                          _c(
                            "table",
                            {
                              staticClass:
                                "table table-stripped table-center table-hover"
                            },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$tc("items.item", 2)))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$t("estimates.item.quantity"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$t("estimates.item.price"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.discountPerItem === "YES"
                                    ? _c("th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("estimates.discount"))
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$t("estimates.item.amount"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("estimates.action")))
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.newEstimate.items, function(
                                  item,
                                  index
                                ) {
                                  return _c("estimate-item", {
                                    key: item.id,
                                    attrs: {
                                      index: index,
                                      "item-data": item,
                                      currency: _vm.currency,
                                      "estimate-items": _vm.newEstimate.items,
                                      "tax-per-item": _vm.taxPerItem,
                                      "discount-per-item": _vm.discountPerItem
                                    },
                                    on: {
                                      remove: _vm.removeItem,
                                      update: _vm.updateItem,
                                      itemValidate: _vm.checkItemsData,
                                      addItem: _vm.addItem
                                    }
                                  })
                                }),
                                1
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-responsive mt-4" }, [
                          _c(
                            "table",
                            {
                              staticClass:
                                "table table-stripped table-center table-hover"
                            },
                            [
                              _c("thead"),
                              _vm._v(" "),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td"),
                                  _vm._v(" "),
                                  _c("td"),
                                  _vm._v(" "),
                                  _c("td"),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("estimates.sub_total"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.formatMoney(
                                            _vm.subtotal,
                                            _vm.currency
                                          )
                                        )
                                      }
                                    })
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm.discountPerItem === "NO" ||
                                _vm.discountPerItem === null
                                  ? _c("tr", [
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-right",
                                          staticStyle: { width: "20%" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("estimates.discount"))
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _c(
                                          "div",
                                          { staticClass: "row float-right" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.discount,
                                                      expression: "discount"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  class: {
                                                    "is-invalid":
                                                      _vm.$v.newEstimate
                                                        .discount_val.$error
                                                  },
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    value: _vm.discount
                                                  },
                                                  on: {
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.discount =
                                                          $event.target.value
                                                      },
                                                      function($event) {
                                                        return _vm.$v.newEstimate.discount_val.$touch()
                                                      }
                                                    ]
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.discounttype,
                                                        expression:
                                                          "discounttype"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.discounttype = $event
                                                          .target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "fixed"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            (_vm.currency &&
                                                              _vm.currency
                                                                .symbol) ||
                                                              _vm.defaultCurrency
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "percentage"
                                                        }
                                                      },
                                                      [_vm._v("%")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.taxPerItem === "NO" ||
                                _vm.taxPerItem === null
                                  ? _c("tr", [
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(_vm._s(_vm.$t("estimates.tax")))
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-right",
                                          staticStyle: { width: "20%" }
                                        },
                                        [
                                          _vm.taxPerItem === "NO" ||
                                          _vm.taxPerItem === null
                                            ? _c(
                                                "div",
                                                [
                                                  _vm._l(
                                                    _vm.newEstimate.taxes,
                                                    function(tax, index) {
                                                      return _c("tax", {
                                                        key: tax.id,
                                                        attrs: {
                                                          index: index,
                                                          total:
                                                            _vm.subtotalWithDiscount,
                                                          tax: tax,
                                                          taxes:
                                                            _vm.newEstimate
                                                              .taxes,
                                                          currency:
                                                            _vm.currency,
                                                          "total-tax":
                                                            _vm.totalSimpleTax
                                                        },
                                                        on: {
                                                          remove:
                                                            _vm.removeEstimateTax,
                                                          update: _vm.updateTax
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.taxPerItem === "NO" ||
                                                  _vm.taxPerItem === null
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "float-right pt-2 pb-4",
                                                          attrs: {
                                                            slot: "activator"
                                                          },
                                                          slot: "activator"
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              on: {
                                                                click:
                                                                  _vm.openTaxModal
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "+ " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "estimates.add_tax"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ])
                                  : _vm.taxPerItem === "YES" ||
                                    _vm.taxPerItem !== null
                                  ? _c("tr", [
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td"),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm.taxPerItem === "YES" ||
                                        _vm.taxPerItem !== null
                                          ? _c(
                                              "div",
                                              _vm._l(_vm.allTaxes, function(
                                                tax
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: tax.tax_type_id,
                                                    staticClass:
                                                      "flex items-center justify-between w-full"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "m-0 text-sm font-semibold leading-5 text-gray-500 uppercase"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(tax.name) +
                                                            " - " +
                                                            _vm._s(
                                                              tax.percent
                                                            ) +
                                                            "%\n                                          "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "flex items-center justify-center m-0 text-lg text-black uppercase",
                                                        staticStyle: {
                                                          "font-size": "18px"
                                                        }
                                                      },
                                                      [
                                                        _c("div", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.$utils.formatMoney(
                                                                tax.amount,
                                                                _vm.currency
                                                              )
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }),
                                              0
                                            )
                                          : _vm._e()
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td"),
                                  _vm._v(" "),
                                  _c("td"),
                                  _vm._v(" "),
                                  _c("td"),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("estimates.total")) + ":"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.formatMoney(
                                            _vm.total,
                                            _vm.currency
                                          )
                                        )
                                      }
                                    })
                                  ])
                                ])
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-right mt-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "submit", disabled: _vm.isLoading }
                            },
                            [
                              _vm.isLoading
                                ? _c("i", {
                                    staticClass: "fas fa-spinner fa-spin"
                                  })
                                : _vm._e(),
                              _vm._v(
                                " " + _vm._s(_vm.$t("estimates.save_estimate"))
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("tax-select-popup", {
        ref: "taxmodal",
        attrs: { taxes: _vm.newEstimate.taxes },
        on: { select: _vm.onSelectTax }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }