var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content container-fluid" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(_vm._s(_vm.$t("customers.title")))
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                  _vm._v(_vm._s(_vm.$t("general.home")))
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item active" }, [
              _vm._v(_vm._s(_vm.$t("customers.title")))
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary mr-1",
                attrs: { to: "customers/create" }
              },
              [_c("i", { staticClass: "fas fa-plus" })]
            ),
            _vm._v(" "),
            _vm.totalCustomers
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-primary filter-btn",
                    attrs: { id: "filter_search" },
                    on: {
                      click: function($event) {
                        return _vm.toggleFilter(1)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-filter" })]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFilters,
            expression: "showFilters"
          }
        ],
        staticClass: "card filter-card"
      },
      [
        _c("div", { staticClass: "card-body pb-0" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-6 col-md-3" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("customers.display_name")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.display_name,
                      expression: "filters.display_name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.filters.display_name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "display_name", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6 col-md-3" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("customers.contact_name")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.contact_name,
                      expression: "filters.contact_name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.filters.contact_name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "contact_name", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6 col-md-3" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("customers.phone")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.phone,
                      expression: "filters.phone"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.filters.phone },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "phone", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "label",
            {
              staticClass:
                "absolute text-sm leading-snug text-black cursor-pointer text-danger",
              staticStyle: { top: "10px", right: "15px", position: "absolute" },
              on: { click: _vm.clearFilter }
            },
            [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _vm.isRequestOngoing
          ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "custom_Card" }, [
            _c("div", { staticClass: "card card-table bg-transaparent" }, [
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "table-responsive" },
                  [
                    _c("vuetable", {
                      ref: "vuetable",
                      attrs: {
                        "api-mode": false,
                        fields: _vm.fields,
                        "per-page": _vm.perPage,
                        "pagination-path": "pagination",
                        "sort-order": _vm.sortOrder,
                        "no-data-template": _vm.showNomessage
                          ? "No records available"
                          : "",
                        "data-manager": _vm.dataManager,
                        css: _vm.css.table
                      },
                      on: { "vuetable:pagination-data": _vm.onPaginationData },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "name",
                            fn: function(props) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "avatar avatar-sm mr-2",
                                    attrs: {
                                      to: {
                                        path:
                                          "customers/" +
                                          props.rowData.id +
                                          "/view"
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "avatar-img rounded-circle",
                                      attrs: {
                                        src: _vm.$utils.userImage(
                                          props.rowData.avatar
                                        ),
                                        alt: "User Image"
                                      },
                                      on: { error: _vm.altImg }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path:
                                          "customers/" +
                                          props.rowData.id +
                                          "/view"
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(props.rowData.name))]
                                )
                              ]
                            }
                          },
                          {
                            key: "contact_name",
                            fn: function(props) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.rowData.contact_name
                                        ? props.rowData.contact_name
                                        : "No Contact Name"
                                    )
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "phone",
                            fn: function(props) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      props.rowData.phone
                                        ? props.rowData.phone
                                        : "No Contact Number"
                                    )
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: _vm.$t("customers.action"),
                            fn: function(props) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "table-button-container" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-white text-success mr-2",
                                        attrs: {
                                          to:
                                            "customers/" +
                                            props.rowData.id +
                                            "/edit"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-edit mr-1"
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("general.edit"))
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-white text-info mr-2",
                                        attrs: {
                                          to:
                                            "customers/" +
                                            props.rowData.id +
                                            "/view"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-edit mr-1"
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("general.view"))
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-white text-danger mr-2",
                                        attrs: { href: "javascript:void(0);" },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeCustomer(
                                              props.rowData.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-trash-alt mr-1"
                                        }),
                                        _vm._v(_vm._s(_vm.$t("general.delete")))
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _vm._v(" "),
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }