var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [_vm._v(_vm._s(_vm.$t("wizard.req.system_req")))]),
    _vm._v(" "),
    _c("div", [
      _vm._v("\n    " + _vm._s(_vm.$t("wizard.req.system_req_desc")) + "\n  ")
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", [
        _vm.phpSupportInfo
          ? _c("div", { staticClass: "p-3 border mb-2 mt-2" }, [
              _c("div", { staticClass: "text-sm" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("wizard.req.php_req_version", {
                        version: _vm.phpSupportInfo.minimum
                      })
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.phpSupportInfo.current) +
                    "\n          "
                ),
                _vm.phpSupportInfo.supported
                  ? _c("span", { staticClass: "fas fa-circle text-success" })
                  : _c("span", { staticClass: "fas fa-circle text-danger" })
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.requirements
          ? _c(
              "div",
              _vm._l(_vm.requirements, function(requirement, index) {
                return _c("div", { key: index, staticClass: "mb-1" }, [
                  _c("div", { staticClass: "col-span-2 text-sm" }, [
                    _vm._v("\n            " + _vm._s(index) + "\n          ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    requirement ? _c("span") : _c("span")
                  ])
                ])
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.hasNext
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary mt-4",
              attrs: { type: "button", variant: "primary" },
              on: { click: _vm.next }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("wizard.continue")) + "\n      "
              ),
              _c("arrow-right-icon", { staticClass: "h-5 ml-2 -mr-1" })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.requirements
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary mt-4",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary"
              },
              on: { click: _vm.getRequirements }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("wizard.req.check_req")) + "\n    "
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }