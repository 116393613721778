var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col items-center justify-between w-full h-32 pt-10 step-indicator"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "form-wizard",
        {
          staticClass: "wizardnew",
          scopedSlots: _vm._u([
            {
              key: "step",
              fn: function(props) {
                return [
                  _c("wizard-step", {
                    key: props.tab.title,
                    attrs: {
                      tab: props.tab,
                      transition: props.transition,
                      index: props.index
                    }
                  })
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _vm._l(_vm.tabs, function(tab) {
            return _c(
              "tab-content",
              { key: tab.title, attrs: { title: tab.title, icon: tab.icon } },
              [_c(_vm.tabData, { tag: "component", on: { next: _vm.setTab } })],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "installer-logo" }, [
      _c("img", {
        staticClass: "img-fluid logo-dark mt-4",
        attrs: {
          id: "logo-kanakku",
          src: "/assets/img/logo.png",
          alt: "kanakku Logo"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }