var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal custom-modal fade",
        attrs: { id: "add_custom_field", role: "dialog" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submitCustomFieldData.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.name,
                            expression: "formData.name"
                          }
                        ],
                        ref: "name",
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.formData.name.$error },
                        attrs: { type: "text" },
                        domProps: { value: _vm.formData.name },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "name",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.$v.formData.name.$touch()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.name.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.nameError))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.model_type,
                              expression: "formData.model_type"
                            }
                          ],
                          staticClass: "select form-control",
                          class: {
                            "is-invalid": _vm.$v.formData.model_type.$error
                          },
                          on: {
                            input: function($event) {
                              return _vm.$v.formData.model_type.$touch()
                            },
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.formData,
                                "model_type",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", [_vm._v("Select")]),
                          _vm._v(" "),
                          _vm._l(_vm.modelTypes, function(item, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: item } },
                              [_vm._v(_vm._s(item))]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.$v.formData.model_type.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.modalTypeError))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch ml-2" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.is_required,
                              expression: "formData.is_required"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.formData.is_required)
                              ? _vm._i(_vm.formData.is_required, null) > -1
                              : _vm.formData.is_required
                          },
                          on: {
                            click: function($event) {
                              _vm.formData.is_required = !_vm.formData
                                .is_required
                            },
                            change: function($event) {
                              var $$a = _vm.formData.is_required,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.formData,
                                      "is_required",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.formData,
                                      "is_required",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.formData, "is_required", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectType,
                              expression: "selectType"
                            }
                          ],
                          staticClass: "select form-control",
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.onSelectTypeChange
                            ]
                          }
                        },
                        [
                          _c("option", [_vm._v("Select")]),
                          _vm._v(" "),
                          _vm._l(_vm.dataTypes, function(item, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: item.value } },
                              [_vm._v(_vm._s(item.label))]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Label")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.label,
                            expression: "formData.label"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.formData.label.$error },
                        attrs: { type: "text" },
                        domProps: { value: _vm.formData.label },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "label",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.$v.formData.label.$touch()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.label.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.labelError))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Default value")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.default_answer,
                            expression: "formData.default_answer"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.formData.default_answer },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formData,
                              "default_answer",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Placeholder")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.placeholder,
                            expression: "formData.placeholder"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.formData.placeholder },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formData,
                              "placeholder",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Order")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.order,
                            expression: "formData.order"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.formData.order.$error },
                        attrs: { type: "number" },
                        domProps: { value: _vm.formData.order },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "order",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.$v.formData.order.$touch()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.order.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.orderError))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "submit-section" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { loading: _vm.isLoading },
                          on: { click: _vm.closeCategoryModal }
                        },
                        [
                          _c("i", { staticClass: "fas fa-window-close" }),
                          _vm._v("\n                          Cancel")
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { disabled: _vm.isLoading }
                        },
                        [
                          _vm.isLoading
                            ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
                            : _vm._e(),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                !_vm.isEdit
                                  ? _vm.$t("general.save")
                                  : _vm.$t("general.update")
                              )
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("button", {
      staticClass: "btn btn-primary d-none",
      attrs: {
        type: "button",
        id: "openmodal",
        "data-toggle": "modal",
        "data-target": "#add_custom_field"
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Add Custom Field")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            id: "cancelbutton",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Name "),
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Modal"),
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Required"),
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Type"),
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }