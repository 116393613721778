var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "form-control-label" }, [
          _vm._v("Email Address")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.email,
              expression: "formData.email"
            }
          ],
          staticClass: "form-control",
          class: { "is-invalid": _vm.$v.formData.email.$error },
          attrs: { disabled: _vm.isSent, name: "email", type: "text" },
          domProps: { value: _vm.formData.email },
          on: {
            blur: function($event) {
              return _vm.$v.formData.email.$touch()
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "email", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.$v.formData.email.$error
          ? _c("div", [
              !_vm.$v.formData.email.required
                ? _c("span", { staticClass: "text-sm text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("validation.required")) +
                        "\n          "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.formData.email.email
                ? _c("span", { staticClass: "text-sm text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("validation.email_incorrect")) +
                        "\n          "
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-lg btn-block btn-primary",
            attrs: { type: "submit" }
          },
          [
            !_vm.isSent
              ? _c("div", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("validation.send_reset_link")) +
                      "\n          "
                  )
                ])
              : _c("div", [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("validation.not_yet")) +
                      "\n          "
                  )
                ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }