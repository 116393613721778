var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex items-center justify-between mb-3" },
    [
      _c("label", { staticClass: "pr-2 mb-0", attrs: { align: "right" } }, [
        _vm._v("\n      " + _vm._s(_vm.$t("estimates.tax")) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex items-center text-base",
          staticStyle: { flex: "4", display: "flex" }
        },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selecttax,
                  expression: "selecttax"
                }
              ],
              staticClass: "select form-control",
              staticStyle: { width: "140px" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selecttax = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.filteredTypes, function(item) {
              return _c(
                "option",
                {
                  key: item.id,
                  attrs: { disabled: _vm.taxData.tax_type_id === item.id },
                  domProps: { value: item.id }
                },
                [_vm._v(_vm._s(item.name) + " - " + _vm._s(item.percent) + "%")]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { display: "flex", "align-items": "center" },
              attrs: { slot: "afterList" },
              slot: "afterList"
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  staticStyle: { margin: "0 10px" },
                  attrs: { type: "button" },
                  on: { click: _vm.openTaxModal }
                },
                [
                  _c("label", { staticStyle: { margin: "0" } }, [
                    _vm._v(_vm._s(_vm.$t("estimates.add_new_tax")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text-sm text-right",
                  staticStyle: { flex: "3" }
                },
                [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(_vm.taxAmount, _vm.currency)
                      )
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer"
                },
                [
                  _vm.taxes.length && _vm.index !== _vm.taxes.length - 1
                    ? _c("trash-icon", {
                        staticClass: "h-5 text-gray-700",
                        attrs: { icon: "trash-alt" },
                        on: {
                          click: function($event) {
                            return _vm.removeTax(_vm.index)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("br")
        ]
      ),
      _vm._v(" "),
      _c("TaxTypeModal", { ref: "modal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }