var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.next()
        }
      }
    },
    [
      _c("div", [
        _c("div", { staticClass: "row mt-4" }, [
          _c("div", { staticClass: "form-group col-md-6" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("wizard.database.app_url")))]),
            _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.databaseData.app_url,
                  expression: "databaseData.app_url",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.$v.databaseData.app_url.$error },
              attrs: { type: "text", name: "name" },
              domProps: { value: _vm.databaseData.app_url },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.databaseData,
                      "app_url",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    return _vm.$v.databaseData.app_url.$touch()
                  }
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.databaseData.app_url.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v(_vm._s(_vm.urlError))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group col-md-6" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("wizard.database.app_domain")))]),
            _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.databaseData.app_domain,
                  expression: "databaseData.app_domain",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.$v.databaseData.app_domain.$error },
              attrs: { type: "text", name: "name", placeholder: "kanakku.com" },
              domProps: { value: _vm.databaseData.app_domain },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.databaseData,
                      "app_domain",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    return _vm.$v.databaseData.app_domain.$touch()
                  }
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.databaseData.app_domain.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v(_vm._s(_vm.domainError))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "form-group col-md-6" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("wizard.database.connection")))]),
            _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.databaseData.database_connection,
                    expression: "databaseData.database_connection"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.$v.databaseData.database_connection.$error
                },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.databaseData,
                        "database_connection",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    _vm.onChangeConnection
                  ]
                }
              },
              _vm._l(_vm.connections, function(item) {
                return _c("option", { key: item }, [
                  _vm._v(
                    "\n                    " + _vm._s(item) + "\n            "
                  )
                ])
              }),
              0
            ),
            _vm._v(" "),
            _vm.$v.databaseData.database_connection.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v(_vm._s(_vm.connectionError))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group col-md-6" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("wizard.database.db_path")))]),
            _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.databaseData.database_name,
                  expression: "databaseData.database_name",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.$v.databaseData.database_name.$error },
              attrs: { type: "text", name: "database_name" },
              domProps: { value: _vm.databaseData.database_name },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.databaseData,
                      "database_name",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    return _vm.$v.databaseData.database_name.$touch()
                  }
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.databaseData.database_name.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v(_vm._s(_vm.nameError))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isFetching,
                expression: "!isFetching"
              }
            ],
            staticClass: "btn btn-primary mt-4",
            attrs: {
              loading: _vm.isLoading,
              disabled: _vm.isLoading,
              variant: "primary",
              type: "submit"
            }
          },
          [
            !_vm.isLoading
              ? _c("save-icon", { staticClass: "h-5 mr-2" })
              : _vm._e(),
            _vm._v("\n      " + _vm._s(_vm.$t("wizard.save_cont")) + "\n    ")
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }