var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("navigation.settings")))
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                    _vm._v(_vm._s(_vm.$t("general.home")))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("li", { staticClass: "breadcrumb-item active" }, [
                _vm._v(_vm._s(_vm.$t("navigation.settings")))
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-3 col-md-4" }, [
          _c("div", { staticClass: "widget settings-menu" }, [
            _c("ul", [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/user-profile" }
                    },
                    [
                      _c("i", { staticClass: "far fa-user" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("settings.account_settings.account_settings")
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/company-info" }
                    },
                    [
                      _c("i", { staticClass: "far fa-list-alt" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("settings.menu_title.company_information")
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/customization" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-edit" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.customization"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/preferences" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-cog" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.preferences"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/notifications" }
                    },
                    [
                      _c("i", { staticClass: "far fa-bell" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.notifications"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/tax-types" }
                    },
                    [
                      _c("i", { staticClass: "far fa-check-square" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("settings.menu_title.tax_types")))
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/payment-mode" }
                    },
                    [
                      _c("i", { staticClass: "far fa-credit-card" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.payment_modes"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/custom-fields" }
                    },
                    [
                      _c("i", { staticClass: "far fa-bell" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.custom_fields"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/expense-category" }
                    },
                    [
                      _c("i", { staticClass: "far fa-list-alt" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.expense_category"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/mail-configuration" }
                    },
                    [
                      _c("i", { staticClass: "far fa-check-square" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("settings.mail.mail_config")))
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xl-9 col-md-8 mt-3" },
          [
            _vm.isRequestOnGoing
              ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "card card-table" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h5", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.$t("settings.tax_types.title")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-auto" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-outline-primary btn-sm",
                        on: { click: _vm.openTaxModal }
                      },
                      [_vm._v(_vm._s(_vm.$t("settings.tax_types.add_new_tax")))]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "table-responsive" },
                  [
                    _c("vuetable", {
                      ref: "vuetable",
                      attrs: {
                        "api-mode": false,
                        fields: _vm.fields,
                        "per-page": _vm.perPage,
                        "pagination-path": "pagination",
                        "sort-order": _vm.sortOrder,
                        "no-data-template": _vm.showNomessage
                          ? "No records available"
                          : "",
                        "data-manager": _vm.dataManager,
                        css: _vm.css.table
                      },
                      on: { "vuetable:pagination-data": _vm.onPaginationData },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "name",
                            fn: function(props) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(props.rowData.name) + " ")
                                ])
                              ]
                            }
                          },
                          {
                            key: "compound_tax",
                            fn: function(props) {
                              return [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      "background-color":
                                        "" +
                                        _vm.$utils.getBadgeStatusColor(
                                          props.rowData.compound_tax
                                            ? "YES"
                                            : "NO"
                                        ).bgColor,
                                      color: _vm.$utils.getBadgeStatusColor(
                                        props.rowData.compound_tax
                                          ? "YES"
                                          : "NO"
                                      ).color
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        props.rowData.compound_tax
                                          ? "Yes"
                                          : "No".replace("_", " ")
                                      )
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: _vm.$t("general.actions"),
                            fn: function(props) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "table-button-container" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-white text-success mr-2",
                                        on: {
                                          click: function($event) {
                                            return _vm.editTax(props.rowData.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-edit mr-1"
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("general.edit"))
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-white text-danger mr-2",
                                        on: {
                                          click: function($event) {
                                            return _vm.removeTax(
                                              props.rowData.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-trash-alt mr-1"
                                        }),
                                        _vm._v(_vm._s(_vm.$t("general.delete")))
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _vm._v(" "),
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass:
                      "row form-group toggle-switch mt-4 container-fluid",
                    attrs: { for: "preferencesSwitch1" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "col-8 col-sm-9 toggle-switch-content ml-0"
                      },
                      [
                        _c("span", { staticClass: "d-block text-dark" }, [
                          _vm._v(
                            _vm._s(_vm.$t("settings.tax_types.tax_per_item"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "d-block text-muted" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "settings.tax_types.tax_setting_description"
                              )
                            )
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "col-4 col-sm-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.tax_per_item,
                            expression: "formData.tax_per_item"
                          }
                        ],
                        staticClass: "toggle-switch-input",
                        attrs: { type: "checkbox", id: "preferencesSwitch1" },
                        domProps: {
                          checked: Array.isArray(_vm.formData.tax_per_item)
                            ? _vm._i(_vm.formData.tax_per_item, null) > -1
                            : _vm.formData.tax_per_item
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.formData.tax_per_item,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.formData,
                                      "tax_per_item",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.formData,
                                      "tax_per_item",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.formData, "tax_per_item", $$c)
                              }
                            },
                            _vm.setTax
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "toggle-switch-label ml-auto" },
                        [_c("span", { staticClass: "toggle-switch-indicator" })]
                      )
                    ])
                  ]
                )
              ])
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("TaxTypeModal", { ref: "modal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }