var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v(_vm._s(_vm.$t("wizard.preferences")))]),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.$t("wizard.preferences_desc")))]),
      _vm._v(" "),
      _vm.isFetching
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.next.apply(null, arguments)
            }
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "row mt-4" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.currency")))]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.settingData.currency,
                        expression: "settingData.currency"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.$v.settingData.currency.$error },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.settingData,
                            "currency",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function($event) {
                          return _vm.$v.settingData.currency.$touch()
                        }
                      ]
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("settings.currencies.select_currency"))
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.currencies, function(item) {
                      return _c(
                        "option",
                        { key: item.id, domProps: { value: item.id } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.$v.settingData.currency.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.currencyError))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("settings.preferences.default_language"))
                  )
                ]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.settingData.language,
                        expression: "settingData.language"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.$v.settingData.language.$error },
                    attrs: { label: "name" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.settingData,
                          "language",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("settings.preferences.select_language"))
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.languages, function(item) {
                      return _c(
                        "option",
                        { key: item.id, domProps: { value: item.code } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.$v.settingData.language.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.languageError))
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.date_format")))]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.settingData.dateFormat,
                        expression: "settingData.dateFormat"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      "is-invalid": _vm.$v.settingData.dateFormat.$error
                    },
                    attrs: { label: "display_date" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.settingData,
                          "dateFormat",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.preferences.select_date_format")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.dateFormats, function(item) {
                      return _c(
                        "option",
                        {
                          key: item.carbon_format_value,
                          domProps: { value: item.carbon_format_value }
                        },
                        [_vm._v(_vm._s(item.carbon_format_value))]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.$v.settingData.dateFormat.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.dateFormatError))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.time_zone")))]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.settingData.timeZone,
                        expression: "settingData.timeZone"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.$v.settingData.timeZone.$error },
                    attrs: { label: "key" },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.settingData,
                            "timeZone",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.changeDate
                      ]
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(
                        _vm._s(_vm.$tc("settings.preferences.select_time_zone"))
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.timeZones, function(item) {
                      return _c(
                        "option",
                        { key: item.value, domProps: { value: item.value } },
                        [_vm._v(_vm._s(item.key))]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.$v.settingData.timeZone.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.timeZoneError))
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.fiscal_year")))]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.settingData.fiscalYear,
                        expression: "settingData.fiscalYear"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      "is-invalid": _vm.$v.settingData.fiscalYear.$error
                    },
                    attrs: { label: "key" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.settingData,
                          "fiscalYear",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$tc("settings.preferences.select_financial_year")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.fiscalYears, function(item) {
                      return _c(
                        "option",
                        { key: item.value, domProps: { value: item.value } },
                        [_vm._v(_vm._s(item.key))]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.$v.settingData.fiscalYear.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.fiscalYearError))
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-4",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit"
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("wizard.save_cont")) + "\n      "
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }