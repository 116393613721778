var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrapper" }, [
    _c(
      "div",
      { staticClass: "error-box" },
      [
        _c("h1", [_vm._v("404")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("p", { staticClass: "h4 font-weight-normal" }, [
          _vm._v("The page you requested was not found.")
        ]),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "btn btn-primary", attrs: { to: "/admin/dashboard" } },
          [_vm._v("Back to Home")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "h2 mb-3" }, [
      _c("i", { staticClass: "fas fa-exclamation-circle" }),
      _vm._v(" Oops! Page not found!")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }