var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", [
    _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn sent_button mr-1",
                attrs: {
                  to: "/admin/customers/" + _vm.$route.params.id + "/edit"
                }
              },
              [_vm._v(_vm._s(_vm.$t("general.edit")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", "data-toggle": "dropdown" }
              },
              [_vm._v(" " + _vm._s(_vm.$t("customers.new_transaction")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dropdown-menu" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      to:
                        "/admin/estimates/create?customer=" +
                        _vm.$route.params.id
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("estimates.new_estimate")))]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      to:
                        "/admin/invoices/create?customer=" +
                        _vm.$route.params.id
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("invoices.new_invoice")))]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      to:
                        "/admin/payments/create?customer=" +
                        _vm.$route.params.id
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("payments.new_payment")))]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      to:
                        "/admin/expenses/create?customer=" +
                        _vm.$route.params.id
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("expenses.new_expense")))]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-12 d-flex" }, [
        _c(
          "div",
          { staticClass: "card flex-fill" },
          [_c("CustomerChart"), _vm._v(" "), _c("CustomerInfo")],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }