var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal custom-modal fade",
        attrs: { id: "add_tax", role: "dialog" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("settings.customization.payments.add_payment_mode")
                    )
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      id: "cancelbutton",
                      "aria-label": "Close"
                    },
                    on: { click: _vm.closePaymentModeModal }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submitPaymentMode.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$tc("settings.customization.payments.mode_name")
                          )
                        ),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("*")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.name,
                            expression: "formData.name"
                          }
                        ],
                        ref: "name",
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.formData.name.$error },
                        attrs: { type: "text" },
                        domProps: { value: _vm.formData.name },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "name",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.$v.formData.name.$touch()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.name.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.nameError))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "submit-section" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: { click: _vm.closePaymentModeModal }
                        },
                        [
                          _c("i", { staticClass: "fas fa-window-close" }),
                          _vm._v(
                            "\n                          " +
                              _vm._s(_vm.$t("general.cancel"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { disabled: _vm.isLoading }
                        },
                        [
                          _vm.isLoading
                            ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
                            : _vm._e(),
                          _vm._v(
                            "\n                           " +
                              _vm._s(
                                !_vm.isEdit
                                  ? _vm.$t("general.save")
                                  : _vm.$t("general.update")
                              )
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("button", {
      staticClass: "btn btn-primary d-none",
      attrs: {
        type: "button",
        id: "openmodal",
        "data-toggle": "modal",
        "data-target": "#add_tax"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }