var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrapper login-body" }, [
    _c("div", { staticClass: "login-wrapper" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("router-link", { attrs: { to: "/admin" } }, [
            _c("img", {
              staticClass: "img-fluid logo-dark mb-2",
              attrs: { src: "/assets/img/logo.png", alt: "Logo" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "loginbox" }, [
            _c("div", { staticClass: "login-right" }, [
              _c(
                "div",
                { staticClass: "login-right-wrap" },
                [
                  _vm.currentPath == "/login" || _vm.currentPath == "/"
                    ? _c("h1", [_vm._v(_vm._s(_vm.$t("layout_login.login")))])
                    : _vm.currentPath == "/register"
                    ? _c("h1", [_vm._v("Register")])
                    : _vm.currentPath == "/forgot-password"
                    ? _c("h1", [_vm._v("Forgot Password?")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentPath == "/login" || _vm.currentPath == "/"
                    ? _c("p", { staticClass: "account-subtitle" }, [
                        _vm._v(_vm._s(_vm.$t("layout_login.access_dashboard")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentPath == "/register"
                    ? _c("p", { staticClass: "account-subtitle" }, [
                        _vm._v(_vm._s(_vm.$t("layout_login.access_dashboard")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentPath == "/forgot-password"
                    ? _c("p", { staticClass: "account-subtitle" }, [
                        _vm._v("Enter your email to get a password reset link")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("router-view"),
                  _vm._v(" "),
                  _vm.currentPath == "/forgot-password"
                    ? _c(
                        "div",
                        { staticClass: "text-center dont-have" },
                        [
                          _vm._v("Remember your password?  "),
                          _c("router-link", { attrs: { to: "/login" } }, [
                            _vm._v("Login")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }