var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-xl-7 d-flex" }, [
      _c("div", { staticClass: "card flex-fill" }, [
        _c("div", { staticClass: "card-header" }, [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center"
            },
            [
              _c("h5", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.$t("dashboard.monthly_chart.title")))
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedYear,
                      expression: "selectedYear"
                    }
                  ],
                  staticClass: "form-control dashboard_chart",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedYear = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.years, function(item) {
                  return _c(
                    "option",
                    { key: item, domProps: { value: item } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                0
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap"
            },
            [
              _c("div", { staticClass: "row ml-2" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("dashboard.chart_info.total_sales")))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "h3 text-primary mr-5" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.getTotalSales,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("dashboard.chart_info.total_receipts"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "h3 text-success mr-5" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.getTotalReceipts,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("dashboard.chart_info.total_expense")))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "h3 text-danger mr-5" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.getTotalExpenses,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("dashboard.chart_info.net_income")))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "h3 text-dark mr-5" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.getNetProfit,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ])
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("apexchart", {
                attrs: {
                  width: "300",
                  type: "bar",
                  options: _vm.options,
                  series: _vm.series
                }
              })
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-xl-5 d-flex" }, [
      _c("div", { staticClass: "card flex-fill" }, [
        _c("div", { staticClass: "card-header" }, [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center"
            },
            [
              _c("h5", { staticClass: "card-title" }, [
                _vm._v("Invoice Analytics")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedValue,
                      expression: "selectedValue"
                    }
                  ],
                  staticClass: "form-control dashboard_chart",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedValue = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.analyticsType, function(item) {
                  return _c(
                    "option",
                    { key: item, domProps: { value: item } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                0
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { attrs: { id: "invoice_chart" } },
            [
              _vm.noData
                ? _c("div", { staticClass: "text-center paymentsempty" }, [
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(" No Data ")
                    ])
                  ])
                : _vm._e(),
              _c("apexchart", {
                attrs: {
                  type: "donut",
                  options: _vm.invoiceoptions,
                  series: _vm.invoiceseries
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text-center text-muted" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-4" }, [
                _c("div", { staticClass: "mt-4" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("h5", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.chartAmount.invoicedAmount,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("div", { staticClass: "mt-4" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("h5", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.chartAmount.receivedAmount,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("div", { staticClass: "mt-4" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("h5", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.chartAmount.pendingAmount,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-2 text-truncate" }, [
      _c("i", { staticClass: "fas fa-circle text-primary mr-1" }),
      _vm._v(" Invoiced")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-2 text-truncate" }, [
      _c("i", { staticClass: "fas fa-circle text-success mr-1" }),
      _vm._v(" Received")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-2 text-truncate" }, [
      _c("i", { staticClass: "fas fa-circle text-danger mr-1" }),
      _vm._v(" Pending")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }