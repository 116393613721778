var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal custom-modal fade",
        attrs: { id: "add_estimate", role: "dialog" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(_vm._s(_vm.$t("estimates.send_estimate")))
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      id: "cancelbutton",
                      "aria-label": "Close"
                    },
                    on: { click: _vm.closeSendEstimateModal }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.sendEstimateData.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("general.from"))),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("*")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.from,
                            expression: "formData.from"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.formData.from.$error },
                        attrs: { type: "text" },
                        domProps: { value: _vm.formData.from },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "from",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.$v.formData.from.$touch()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.from.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.fromError))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("general.to"))),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("*")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.to,
                            expression: "formData.to"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.formData.to.$error },
                        attrs: { type: "text" },
                        domProps: { value: _vm.formData.to },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.formData, "to", $event.target.value)
                            },
                            function($event) {
                              return _vm.$v.formData.to.$touch()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.to.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.toError))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("general.subject")))]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.subject,
                            expression: "formData.subject"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.formData.subject.$error },
                        attrs: { type: "text", rows: "3", columns: "5" },
                        domProps: { value: _vm.formData.subject },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "subject",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.$v.formData.subject.$touch()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.formData.subject.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.subjectError))
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("general.body")))]),
                        _vm._v(" "),
                        _c("vue-editor", {
                          class: { "is-invalid": _vm.$v.formData.body.$error },
                          staticStyle: { height: "450px:min-width:1000px" },
                          attrs: { id: "editor1" },
                          on: {
                            input: function($event) {
                              return _vm.$v.formData.body.$touch()
                            }
                          },
                          model: {
                            value: _vm.formData.body,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "body", $$v)
                            },
                            expression: "formData.body"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _c("base-custom-input", {
                          staticClass: "mb-4",
                          attrs: { fields: _vm.estimateMailFields },
                          model: {
                            value: _vm.formData.body,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "body", $$v)
                            },
                            expression: "formData.body"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "submit-section" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: { click: _vm.closeSendEstimateModal }
                        },
                        [
                          _c("i", { staticClass: "fas fa-window-close" }),
                          _vm._v(
                            "\n                          " +
                              _vm._s(_vm.$t("general.cancel"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { disabled: _vm.isLoading }
                        },
                        [
                          _vm.isLoading
                            ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
                            : _vm._e(),
                          _vm._v(
                            "\n                         " +
                              _vm._s(_vm.$t("general.send")) +
                              "\n                         "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("button", {
      staticClass: "btn btn-primary d-none",
      attrs: {
        type: "button",
        id: "openmodal",
        "data-toggle": "modal",
        "data-target": "#add_estimate"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }