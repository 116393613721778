var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dropdown" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary dropdown-toggle",
          attrs: { type: "button", "data-toggle": "dropdown" }
        },
        [
          _vm._v(
            "\r\n    " +
              _vm._s(_vm.$t("settings.customization.addresses.insert_fields")) +
              "\r\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-menu p-3 double_icon" }, [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          _vm._l(_vm.fieldList, function(type, index) {
            return _c(
              "ul",
              { key: index, staticClass: "list-none p-2" },
              [
                _c("li", { staticClass: "text-grey" }, [
                  _vm._v(
                    "\r\n              " +
                      _vm._s(type.label) +
                      "\r\n            "
                  )
                ]),
                _vm._v(" "),
                _vm._l(type.fields, function(field, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function($event) {
                          return _vm.insertField(field.value)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("chevron-double-right-icon"),
                          _vm._v(_vm._s(field.label) + "\r\n              ")
                        ],
                        1
                      )
                    ]
                  )
                })
              ],
              2
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }