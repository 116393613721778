var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    { staticClass: "profit-loss-reports reports" },
    [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$tc("reports.report", 2)))
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                _vm._v(_vm._s(_vm.$t("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/admin/reports" } }, [
                _vm._v(_vm._s(_vm.$tc("reports.report", 2)))
              ])
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary floatright m-3",
          on: {
            click: function($event) {
              return _vm.onDownload()
            }
          }
        },
        [
          _c("download-icon"),
          _vm._v(
            "\n        " + _vm._s(_vm.$t("reports.download_pdf")) + "\n      "
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary floatright m-3",
          on: {
            click: function($event) {
              return _vm.viewPdf()
            }
          }
        },
        [
          _c("document-text-icon"),
          _vm._v("\n        " + _vm._s(_vm.$t("reports.view_pdf")) + "\n      ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("router-view", { ref: "report" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }