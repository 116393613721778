var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", { staticClass: "card card-body" }, [
        _c(
          "form",
          {
            staticClass: "mt-6",
            attrs: { action: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.updateEstimateSetting.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [
                _vm._v(
                  _vm._s(
                    _vm.$t("settings.customization.estimates.estimate_prefix")
                  )
                )
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.estimates.estimate_prefix,
                    expression: "estimates.estimate_prefix"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.$v.estimates.estimate_prefix.$error
                },
                staticStyle: { "max-width": "30%" },
                attrs: { type: "text" },
                domProps: { value: _vm.estimates.estimate_prefix },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.estimates,
                        "estimate_prefix",
                        $event.target.value
                      )
                    },
                    function($event) {
                      return _vm.$v.estimates.estimate_prefix.$touch()
                    }
                  ],
                  keyup: function($event) {
                    return _vm.changeToUppercase("ESTIMATES")
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "settings.customization.estimates.default_estimate_email_body"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("vue-editor", {
                  model: {
                    value: _vm.estimates.estimate_mail_body,
                    callback: function($$v) {
                      _vm.$set(_vm.estimates, "estimate_mail_body", $$v)
                    },
                    expression: "estimates.estimate_mail_body"
                  }
                }),
                _vm._v(" "),
                _c("base-custom-input", {
                  staticClass: "mt-2",
                  attrs: { fields: _vm.mailFields },
                  model: {
                    value: _vm.estimates.estimate_mail_body,
                    callback: function($$v) {
                      _vm.$set(_vm.estimates, "estimate_mail_body", $$v)
                    },
                    expression: "estimates.estimate_mail_body"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "settings.customization.estimates.company_address_format"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("vue-editor", {
                  model: {
                    value: _vm.estimates.company_address_format,
                    callback: function($$v) {
                      _vm.$set(_vm.estimates, "company_address_format", $$v)
                    },
                    expression: "estimates.company_address_format"
                  }
                }),
                _vm._v(" "),
                _c("base-custom-input", {
                  staticClass: "mt-2",
                  attrs: { fields: _vm.companyFields },
                  model: {
                    value: _vm.estimates.company_address_format,
                    callback: function($$v) {
                      _vm.$set(_vm.estimates, "company_address_format", $$v)
                    },
                    expression: "estimates.company_address_format"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "settings.customization.estimates.shipping_address_format"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("vue-editor", {
                  model: {
                    value: _vm.estimates.shipping_address_format,
                    callback: function($$v) {
                      _vm.$set(_vm.estimates, "shipping_address_format", $$v)
                    },
                    expression: "estimates.shipping_address_format"
                  }
                }),
                _vm._v(" "),
                _c("base-custom-input", {
                  staticClass: "mt-2",
                  attrs: { fields: _vm.shippingFields },
                  model: {
                    value: _vm.estimates.shipping_address_format,
                    callback: function($$v) {
                      _vm.$set(_vm.estimates, "shipping_address_format", $$v)
                    },
                    expression: "estimates.shipping_address_format"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "settings.customization.estimates.billing_address_format"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("vue-editor", {
                  model: {
                    value: _vm.estimates.billing_address_format,
                    callback: function($$v) {
                      _vm.$set(_vm.estimates, "billing_address_format", $$v)
                    },
                    expression: "estimates.billing_address_format"
                  }
                }),
                _vm._v(" "),
                _c("base-custom-input", {
                  staticClass: "mt-2",
                  attrs: { fields: _vm.billingFields },
                  model: {
                    value: _vm.estimates.billing_address_format,
                    callback: function($$v) {
                      _vm.$set(_vm.estimates, "billing_address_format", $$v)
                    },
                    expression: "estimates.billing_address_format"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-4",
                attrs: { disabled: _vm.isLoading, type: "submit" }
              },
              [
                _vm.isLoading
                  ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
                  : _vm._e(),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("settings.customization.save")) +
                    "\n    "
                )
              ]
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }