var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", [
    _c("div", { staticClass: "content container-fluid" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _c("div", { staticClass: "col" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("expenses.title")))
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                    _vm._v(_vm._s(_vm.$t("general.home")))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("li", { staticClass: "breadcrumb-item active" }, [
                _vm._v(_vm._s(_vm.$t("expenses.title")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary",
                  attrs: { to: "/admin/expenses/create" }
                },
                [_c("i", { staticClass: "fas fa-plus" })]
              ),
              _vm._v(" "),
              _vm.totalExpenses
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary filter-btn",
                      attrs: {
                        href: "javascript:void(0);",
                        id: "filter_search"
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleFilter(1)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-filter" })]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFilters,
              expression: "showFilters"
            }
          ],
          staticClass: "card filter-card"
        },
        [
          _c("div", { staticClass: "card-body pb-0" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("expenses.customer")))]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.user,
                          expression: "filters.user"
                        }
                      ],
                      staticClass: "select form-control",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filters,
                            "user",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", [_vm._v(_vm._s("Select Customer"))]),
                      _vm._v(" "),
                      _vm._l(_vm.customers, function(item) {
                        return _c(
                          "option",
                          { key: item.id, domProps: { value: item.id } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("expenses.category")))]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.category,
                          expression: "filters.category"
                        }
                      ],
                      staticClass: "select form-control",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filters,
                            "category",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("expenses.categories.select_a_category")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.categories, function(item) {
                        return _c(
                          "option",
                          { key: item.id, domProps: { value: item.id } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("expenses.from_date")))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "datemx" },
                    [
                      _c("datepicker", {
                        attrs: {
                          "disabled-date": _vm.$utils.disabledAfterToday,
                          "value-type": "format",
                          format: "YYYY-MM-DD"
                        },
                        model: {
                          value: _vm.filters.from_date,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "from_date", $$v)
                          },
                          expression: "filters.from_date"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("expenses.to_date")))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "datemx" },
                    [
                      _c("datepicker", {
                        attrs: {
                          "disabled-date": _vm.$utils.disabledAfterToday,
                          "value-type": "format",
                          format: "YYYY-MM-DD"
                        },
                        model: {
                          value: _vm.filters.to_date,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "to_date", $$v)
                          },
                          expression: "filters.to_date"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass:
                "absolute text-sm leading-snug text-black cursor-pointer text-danger",
              staticStyle: { top: "10px", right: "15px", position: "absolute" },
              on: { click: _vm.clearFilter }
            },
            [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _vm.isRequestOngoing
              ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "custom_Card" }, [
              _c("div", { staticClass: "card card-table" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "table-responsive" },
                    [
                      _c("vuetable", {
                        ref: "vuetable",
                        attrs: {
                          "api-mode": false,
                          fields: _vm.fields,
                          "per-page": _vm.perPage,
                          "pagination-path": "pagination",
                          "sort-order": _vm.sortOrder,
                          "no-data-template": _vm.showNomessage
                            ? "No records available"
                            : "",
                          "data-manager": _vm.dataManager,
                          css: _vm.css.table
                        },
                        on: {
                          "vuetable:pagination-data": _vm.onPaginationData
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "user_name",
                              fn: function(props) {
                                return [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "avatar avatar-sm mr-2",
                                      attrs: {
                                        to: {
                                          path:
                                            "customers/" +
                                            props.rowData.user_id +
                                            "/view"
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "avatar-img rounded-circle",
                                        attrs: {
                                          src: _vm.$utils.userImage(
                                            props.rowData.user.avatar
                                          ),
                                          alt: "User Image"
                                        },
                                        on: { error: _vm.altImg }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path:
                                            "customers/" +
                                            props.rowData.user_id +
                                            "/view"
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(props.rowData.user_name))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "notes",
                              fn: function(props) {
                                return [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        props.rowData.notes
                                          ? props.rowData.notes
                                          : "-"
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              }
                            },
                            {
                              key: "amount",
                              fn: function(props) {
                                return [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          props.rowData.amount,
                                          _vm.defaultCurrency
                                        )
                                      )
                                    }
                                  })
                                ]
                              }
                            },
                            {
                              key: _vm.$t("general.actions"),
                              fn: function(props) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "table-button-container" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-white text-success mr-2",
                                          attrs: {
                                            to:
                                              "expenses/" +
                                              props.rowData.id +
                                              "/edit"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "far fa-edit mr-1"
                                          }),
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("general.edit"))
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-white text-danger mr-2",
                                          on: {
                                            click: function($event) {
                                              return _vm.removeExpense(
                                                props.rowData.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "far fa-trash-alt mr-1"
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("general.delete"))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("vuetable-pagination", {
                        ref: "pagination",
                        attrs: { css: _vm.css.pagination },
                        on: {
                          "vuetable-pagination:change-page": _vm.onChangePage
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }