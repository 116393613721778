var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.itemSelect,
            expression: "itemSelect"
          }
        ],
        ref: "baseSelect",
        staticClass: "select form-control",
        class: { "is-invalid": _vm.invalid },
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.itemSelect = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.onTextChange
          ]
        }
      },
      _vm._l(_vm.items, function(item) {
        return _c("option", { key: item.id, domProps: { value: item.id } }, [
          _vm._v(_vm._s(item.name))
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }