var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar", attrs: { id: "sidebar" } }, [
    _c(
      "div",
      { staticClass: "sidebar-inner slimscroll" },
      [
        _c(
          "vue-custom-scrollbar",
          {
            staticClass: "scroll-area",
            attrs: { settings: _vm.settings },
            on: { "ps-scroll-y": _vm.scrollHanle }
          },
          [
            _c(
              "div",
              { staticClass: "sidebar-menu", attrs: { id: "sidebar-menu" } },
              [
                _c("ul", [
                  _c("li", { staticClass: "menu-title" }, [
                    _c("span", [_vm._v("Main")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: { active: _vm.currentPath === "/admin/dashboard" }
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/dashboard" } },
                        [
                          _c("home-icon", {
                            staticClass: "custom-class",
                            attrs: { size: "1.5x" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("navigation.dashboard")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: {
                        active:
                          _vm.currentPath === "/admin/customers" ||
                          _vm.currentPath === "/admin/customers/create" ||
                          _vm.currentPathName === "customers.create" ||
                          _vm.currentPathName === "customers.edit" ||
                          _vm.currentPathName === "customers.view"
                      }
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/customers" } },
                        [
                          _c("users-icon", {
                            staticClass: "custom-class",
                            attrs: { size: "1.5x" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("navigation.customers")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: {
                        active:
                          _vm.currentPath === "/admin/items" ||
                          _vm.currentPathName === "items.create" ||
                          _vm.currentPathName === "items.edit" ||
                          _vm.currentPathName === "items.view"
                      }
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/items" } },
                        [
                          _c("star-icon", {
                            staticClass: "custom-class",
                            attrs: { size: "1.5x" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("navigation.items")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: {
                        active:
                          _vm.currentPath === "/admin/estimates" ||
                          _vm.currentPath === "/admin/estimates/create" ||
                          _vm.currentPathName === "estimates.edit" ||
                          _vm.currentPathName === "estimates.view"
                      }
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/estimates" } },
                        [
                          _c("file-text-icon", {
                            staticClass: "custom-class",
                            attrs: { size: "1.5x" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("navigation.estimates")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: {
                        active:
                          _vm.currentPath === "/admin/invoices" ||
                          _vm.currentPath === "/admin/invoices/create" ||
                          _vm.currentPathName === "invoices.edit" ||
                          _vm.currentPathName === "invoices.view"
                      }
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/invoices" } },
                        [
                          _c("clipboard-icon", {
                            staticClass: "custom-class",
                            attrs: { size: "1.5x" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("navigation.invoices")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: {
                        active:
                          _vm.currentPath === "/admin/payments" ||
                          _vm.currentPath === "/admin/payments/create" ||
                          _vm.currentPathName === "payments.edit" ||
                          _vm.currentPathName === "payments.view"
                      }
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/payments" } },
                        [
                          _c("credit-card-icon", {
                            staticClass: "custom-class",
                            attrs: { size: "1.5x" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("navigation.payments")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: {
                        active:
                          _vm.currentPath === "/admin/expenses" ||
                          _vm.currentPath === "/admin/expenses/create" ||
                          _vm.currentPathName === "expenses.edit"
                      }
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/expenses" } },
                        [
                          _c("package-icon", {
                            staticClass: "custom-class",
                            attrs: { size: "1.5x" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("navigation.expenses")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", { staticClass: "submenu" }, [
                    _c(
                      "a",
                      { attrs: { href: "#" } },
                      [
                        _c("bar-chart-icon", {
                          staticClass: "custom-class",
                          attrs: { size: "1.5x" }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(" Reports")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-arrow" })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("ul", [
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/admin/reports/sales" } },
                            [_vm._v("Sales")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/admin/reports/profit-loss" } },
                            [_vm._v("Profit & Loss")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/admin/reports/expenses" } },
                            [_vm._v("Expenses")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/admin/reports/taxes" } },
                            [_vm._v("Taxes")]
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: {
                        active:
                          _vm.currentPath === "/admin/settings/user-profile" ||
                          _vm.currentPath === "/admin/settings/company-info" ||
                          _vm.currentPath === "/admin/settings/preferences" ||
                          _vm.currentPath === "/admin/settings/notifications" ||
                          _vm.currentPath === "/admin/settings/customization" ||
                          _vm.currentPath === "/admin/settings/tax-types" ||
                          _vm.currentPath === "/admin/settings/payment-mode" ||
                          _vm.currentPath === "/admin/settings/custom-fields" ||
                          _vm.currentPath === "/admin/settings/notes" ||
                          _vm.currentPath ===
                            "/admin/settings/expense-category" ||
                          _vm.currentPath ===
                            "/admin/settings/mail-configuration" ||
                          _vm.currentPath === "/admin/settings/file-disk" ||
                          _vm.currentPath === "/admin/settings/backup"
                      }
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/settings" } },
                        [
                          _c("settings-icon", {
                            staticClass: "custom-class",
                            attrs: { size: "1.5x" }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("navigation.settings")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.currentUser.role === "super admin"
                    ? _c("li", { staticClass: "menu-title" }, [
                        _c("span", [_vm._v("Pages")])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUser.role === "super admin"
                    ? _c(
                        "li",
                        {
                          class: {
                            active:
                              _vm.currentPath === "/admin/users" ||
                              _vm.currentPath === "/admin/users/create" ||
                              _vm.currentPathName === "users.edit"
                          }
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/admin/users" } },
                            [
                              _c("user-icon", {
                                staticClass: "custom-class",
                                attrs: { size: "1.5x" }
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("navigation.users")))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }