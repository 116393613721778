var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "top-nav-search" },
      [_c("global-search", { staticClass: "hidden float-left md:block" })],
      1
    ),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c("ul", { staticClass: "nav user-menu" }, [
      _c("li", { staticClass: "nav-item dropdown has-arrow main-drop" }, [
        _c(
          "a",
          {
            staticClass: "dropdown-toggle nav-link",
            attrs: { href: "#", "data-toggle": "dropdown" }
          },
          [
            _c("span", { staticClass: "user-img" }, [
              _c("img", {
                attrs: {
                  src: _vm.currentUser.avatar
                    ? _vm.currentUser.avatar
                    : "/assets/img/default-avatar.jpg",
                  id: "previewImg",
                  alt: ""
                },
                on: { error: _vm.altImg }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "status online" })
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.currentUser.name))])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dropdown-menu" },
          [
            _c(
              "router-link",
              {
                staticClass: "dropdown-item",
                attrs: { to: "/admin/settings" }
              },
              [
                _c("settings-icon", {
                  staticClass: "custom-class",
                  attrs: { size: "1.5x" }
                }),
                _vm._v("  Settings")
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "dropdown-item", on: { click: _vm.logout } },
              [
                _c("log-out-icon", {
                  staticClass: "custom-class",
                  attrs: { size: "1.5x" }
                }),
                _vm._v("  Logout")
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-left" }, [
      _c("a", { staticClass: "logo", attrs: { href: "index" } }, [
        _c("img", { attrs: { src: "/assets/img/logo.png", alt: "Logo" } })
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "logo logo-small", attrs: { href: "/index" } }, [
        _c("img", {
          attrs: {
            src: "/assets/img/logo-small.png",
            alt: "Logo",
            width: "30",
            height: "30"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "javascript:void(0);", id: "toggle_btn" } },
      [_c("i", { staticClass: "fas fa-bars" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "mobile_btn", attrs: { id: "mobile_btn" } }, [
      _c("i", { staticClass: "fas fa-bars" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }