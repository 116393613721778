var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", [
    _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(_vm._s(_vm.$t("navigation.settings")))
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                  _vm._v(_vm._s(_vm.$t("general.home")))
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item active" }, [
              _vm._v(_vm._s(_vm.$t("navigation.settings")))
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-3 col-md-4" }, [
        _c("div", { staticClass: "widget settings-menu" }, [
          _c("ul", [
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/user-profile" }
                  },
                  [
                    _c("i", { staticClass: "far fa-user" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.account_settings.account_settings")
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/company-info" }
                  },
                  [
                    _c("i", { staticClass: "far fa-list-alt" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.menu_title.company_information")
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/customization" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-edit" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.customization"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/preferences" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-cog" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.menu_title.preferences")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/notifications" }
                  },
                  [
                    _c("i", { staticClass: "far fa-bell" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.notifications"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/tax-types" }
                  },
                  [
                    _c("i", { staticClass: "far fa-check-square" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.menu_title.tax_types")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/payment-mode" }
                  },
                  [
                    _c("i", { staticClass: "far fa-credit-card" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.payment_modes"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/custom-fields" }
                  },
                  [
                    _c("i", { staticClass: "far fa-bell" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.custom_fields"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/expense-category" }
                  },
                  [
                    _c("i", { staticClass: "far fa-list-alt" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.expense_category"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/mail-configuration" }
                  },
                  [
                    _c("i", { staticClass: "far fa-check-square" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.mail.mail_config")))
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-9 col-md-8 mt-3" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.$t("settings.notification.title")))
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("settings.notification.description")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.saveEmail()
                  }
                }
              },
              [
                _vm.isRequestOnGoing
                  ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "notificationmail" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("settings.notification.email")) + " "
                      ),
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.notification_email,
                          expression: "notification_email",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.notification_email.$error },
                      attrs: {
                        type: "email",
                        placeholder: _vm.$tc(
                          "settings.notification.please_enter_email"
                        ),
                        id: "notificationmail"
                      },
                      domProps: { value: _vm.notification_email },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.notification_email = $event.target.value.trim()
                          },
                          function($event) {
                            return _vm.$v.notification_email.$touch()
                          }
                        ],
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.notificationEmailError))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "row form-group toggle-switch",
                    attrs: { for: "notification_switch1" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "col-8 col-sm-9 toggle-switch-content ml-0"
                      },
                      [
                        _c("span", { staticClass: "d-block text-dark" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.notification.invoice_viewed")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "d-block text-muted" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "settings.notification.invoice_viewed_desc"
                              )
                            )
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "col-4 col-sm-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.notify_invoice_viewed,
                            expression: "notify_invoice_viewed"
                          }
                        ],
                        staticClass: "toggle-switch-input",
                        attrs: { type: "checkbox", id: "notification_switch1" },
                        domProps: {
                          checked: Array.isArray(_vm.notify_invoice_viewed)
                            ? _vm._i(_vm.notify_invoice_viewed, null) > -1
                            : _vm.notify_invoice_viewed
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.notify_invoice_viewed,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.notify_invoice_viewed = $$a.concat([
                                      $$v
                                    ]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.notify_invoice_viewed = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.notify_invoice_viewed = $$c
                              }
                            },
                            _vm.setInvoiceViewd
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "toggle-switch-label ml-auto" },
                        [_c("span", { staticClass: "toggle-switch-indicator" })]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "row form-group toggle-switch",
                    attrs: { for: "notification_switch2" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "col-8 col-sm-9 toggle-switch-content ml-0"
                      },
                      [
                        _c("span", { staticClass: "d-block text-dark" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.notification.estimate_viewed")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "d-block text-muted" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "settings.notification.estimate_viewed_desc"
                              )
                            )
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "col-4 col-sm-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.notify_estimate_viewed,
                            expression: "notify_estimate_viewed"
                          }
                        ],
                        staticClass: "toggle-switch-input",
                        attrs: { type: "checkbox", id: "notification_switch2" },
                        domProps: {
                          checked: Array.isArray(_vm.notify_estimate_viewed)
                            ? _vm._i(_vm.notify_estimate_viewed, null) > -1
                            : _vm.notify_estimate_viewed
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.notify_estimate_viewed,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.notify_estimate_viewed = $$a.concat([
                                      $$v
                                    ]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.notify_estimate_viewed = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.notify_estimate_viewed = $$c
                              }
                            },
                            _vm.setEstimateViewd
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "toggle-switch-label ml-auto" },
                        [_c("span", { staticClass: "toggle-switch-indicator" })]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" }
                    },
                    [_vm._v(_vm._s(_vm.$tc("settings.notification.save")))]
                  )
                ])
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }