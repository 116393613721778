var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", staticStyle: { "margin-top": "10% !important" } },
    [
      _vm.isRequestOngoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-3" }, [
        _c("div", { staticClass: "card filter-card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v(" Select Date range")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedRange,
                        expression: "selectedRange"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedRange = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onChangeDateRange
                      ]
                    }
                  },
                  _vm._l(_vm.dateRange, function(item, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: item } },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group datemx datetype" },
                [
                  _c("label", [_vm._v("From Date")]),
                  _vm._v(" "),
                  _c("datepicker", {
                    attrs: {
                      editable: false,
                      clearable: false,
                      "value-type": "format",
                      format: "YYYY-MM-DD"
                    },
                    on: {
                      change: function($event) {
                        return _vm.$v.formData.from_date.$touch()
                      }
                    },
                    model: {
                      value: _vm.formData.from_date,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formData,
                          "from_date",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.from_date"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group datemx datetype" },
                [
                  _c("label", [_vm._v("To Date")]),
                  _vm._v(" "),
                  _c("datepicker", {
                    attrs: {
                      editable: false,
                      clearable: false,
                      "value-type": "format",
                      format: "YYYY-MM-DD"
                    },
                    on: {
                      change: function($event) {
                        return _vm.$v.formData.to_date.$touch()
                      }
                    },
                    model: {
                      value: _vm.formData.to_date,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "to_date", $$v)
                      },
                      expression: "formData.to_date"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-group w-100" }, [
                _c("label", [_vm._v("Report Type")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedType,
                        expression: "selectedType"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.getInitialReport
                      ]
                    }
                  },
                  _vm._l(_vm.reportTypes, function(item, index) {
                    return _c("option", { key: index }, [_vm._v(_vm._s(item))])
                  }),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-3 w-100",
                attrs: { variant: "primary-outline", type: "submit" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.getReports()
                  }
                }
              },
              [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("reports.update_report")) +
                    "\n            "
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-9" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "invoice-item" }, [
              _c("div", { staticClass: "row" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("p", { staticClass: "invoice-details" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.reportData.fromDate) +
                          " - " +
                          _vm._s(_vm.reportData.toDate)
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.selectedType === "By Customer"
              ? _c("div", { staticClass: "invoice-item invoice-table-wrap" }, [
                  _c("h3", { staticClass: "report-title" }, [
                    _vm._v("Sales Report: " + _vm._s(_vm.selectedType))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          { staticClass: "table report-table" },
                          _vm._l(_vm.reportData.invoiceData, function(item) {
                            return _c(
                              "tbody",
                              { key: item.id },
                              [
                                _c("tr", [
                                  _c("th", { attrs: { colspan: "2" } }, [
                                    _vm._v(_vm._s(item.customerName))
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm._l(item.invoiceDetails, function(items) {
                                  return _c("tr", { key: items.id }, [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(items.invoiceDate) +
                                          " (" +
                                          _vm._s(items.invoiceNo) +
                                          ")"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(items.invoiceAmount))
                                    ])
                                  ])
                                }),
                                _vm._v(" "),
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right report-total",
                                      attrs: { colspan: "2" }
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(_vm._s(item.customerAmount))
                                      ])
                                    ]
                                  )
                                ])
                              ],
                              2
                            )
                          }),
                          0
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          { staticClass: "table total-report-table" },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("th", [_vm._v("Total Sales:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("span", { staticClass: "float-right" }, [
                                    _vm._v(
                                      _vm._s(_vm.reportData.invoiceTotalAmount)
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              : _c("div", { staticClass: "invoice-item invoice-table-wrap" }, [
                  _c("h3", { staticClass: "report-title" }, [
                    _vm._v("Sales Report: " + _vm._s(_vm.selectedType))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table report-table" }, [
                          _c(
                            "tbody",
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _vm._l(_vm.reportData.itemData, function(item) {
                                return _c("tr", { key: item.id }, [
                                  _c("td", [_vm._v(_vm._s(item.itemName))]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _vm._v(_vm._s(item.itemAmount))
                                  ])
                                ])
                              }),
                              _vm._v(" "),
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right report-total",
                                    attrs: { colspan: "2" }
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.reportData.itemTotalAmount)
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ],
                            2
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          { staticClass: "table total-report-table" },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("th", [_vm._v("Total Sales:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("span", { staticClass: "float-right" }, [
                                    _vm._v(
                                      _vm._s(_vm.reportData.itemTotalAmount)
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "invoice-logo" }, [
        _c("img", { attrs: { src: "assets/img/logo.png", alt: "logo" } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("th", { attrs: { colspan: "2" } }, [_vm._v("Items")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }