var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", [
    _c("div", { staticClass: "content container-fluid" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _c("div", { staticClass: "col" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("items.title")))
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                    _vm._v(_vm._s(_vm.$t("general.home")))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("li", { staticClass: "breadcrumb-item active" }, [
                _vm._v(_vm._s(_vm.$t("items.title")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-primary mr-1",
                  attrs: { to: "items/create" }
                },
                [_c("i", { staticClass: "fas fa-plus" })]
              ),
              _vm._v(" "),
              _vm.totalItems
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary filter-btn",
                      attrs: { id: "filter_search" },
                      on: {
                        click: function($event) {
                          return _vm.toggleFilter(1)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-filter" })]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFilters,
              expression: "showFilters"
            }
          ],
          staticClass: "card filter-card"
        },
        [
          _c("div", { staticClass: "card-body pb-0" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6 col-md-3" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("items.name")))]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.name,
                        expression: "filters.name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.filters.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.filters, "name", $event.target.value)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 col-md-3" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$tc("items.unit")))]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.unit,
                          expression: "filters.unit"
                        }
                      ],
                      staticClass: "select form-control",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.filters,
                            "unit",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", [_vm._v("Select")]),
                      _vm._v(" "),
                      _vm._l(_vm.itemUnits, function(item) {
                        return _c(
                          "option",
                          { key: item.id, domProps: { value: item.id } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6 col-md-3" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$tc("items.price")))]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.price,
                        expression: "filters.price"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.filters.price },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.filters, "price", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "label",
              {
                staticClass:
                  "absolute text-sm leading-snug text-black cursor-pointer text-danger",
                staticStyle: {
                  top: "10px",
                  right: "15px",
                  position: "absolute"
                },
                on: { click: _vm.clearFilter }
              },
              [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm.isRequestOngoing
            ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "custom_Card" }, [
              _c("div", { staticClass: "card card-table" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "table-responsive" },
                    [
                      _c("vuetable", {
                        ref: "vuetable",
                        attrs: {
                          "api-mode": false,
                          fields: _vm.fields,
                          "per-page": _vm.perPage,
                          "pagination-path": "pagination",
                          "sort-order": _vm.sortOrder,
                          "no-data-template": _vm.showNomessage
                            ? "No records available"
                            : "",
                          "data-manager": _vm.dataManager,
                          css: _vm.css.table
                        },
                        on: {
                          "vuetable:pagination-data": _vm.onPaginationData
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "price",
                              fn: function(props) {
                                return [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          props.rowData.price,
                                          _vm.defaultCurrency
                                        )
                                      )
                                    }
                                  })
                                ]
                              }
                            },
                            {
                              key: "name",
                              fn: function(props) {
                                return [
                                  _vm._v(
                                    "\n                              " +
                                      _vm._s(props.rowData.name) +
                                      "\n                          "
                                  )
                                ]
                              }
                            },
                            {
                              key: _vm.$t("items.action"),
                              fn: function(props) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "table-button-container" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-white text-success mr-2",
                                          attrs: {
                                            to:
                                              "items/" +
                                              props.rowData.id +
                                              "/edit"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-edit mr-1"
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("general.edit")) +
                                              "\n                            "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-white text-danger",
                                          on: {
                                            click: function($event) {
                                              return _vm.removeItems(
                                                props.rowData.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-trash-alt mr-1"
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("general.delete")) +
                                              "\n                            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("vuetable-pagination", {
                        ref: "pagination",
                        attrs: { css: _vm.css.pagination },
                        on: {
                          "vuetable-pagination:change-page": _vm.onChangePage
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }