var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSuperAdmin
    ? _c("base-page", { staticClass: "items" }, [
        _c("div", { staticClass: "page-header" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col" }, [
              _c("h3", { staticClass: "page-title" }, [
                _vm._v(_vm._s(_vm.$t("users.title")))
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                      _vm._v(_vm._s(_vm.$t("general.home")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(_vm._s(_vm.$t("users.title", 2)))
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary mr-1",
                    attrs: { to: "users/create" }
                  },
                  [_c("i", { staticClass: "fas fa-plus" })]
                ),
                _vm._v(" "),
                _vm.totalUsers
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary filter-btn",
                        attrs: { id: "filter_search" },
                        on: {
                          click: function($event) {
                            return _vm.toggleFilter(1)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-filter" })]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters"
              }
            ],
            staticClass: "card filter-card"
          },
          [
            _c("div", { staticClass: "card-body pb-0" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6 col-md-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("users.name")))]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.name,
                          expression: "filters.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.filters.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "name", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6 col-md-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("users.email")))]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.email,
                          expression: "filters.email"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.filters.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "email", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6 col-md-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("users.phone")))]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.phone,
                          expression: "filters.phone"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.filters.phone },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "phone", $event.target.value)
                        }
                      }
                    })
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer text-danger",
                  staticStyle: {
                    top: "10px",
                    right: "15px",
                    position: "absolute"
                  },
                  on: { click: _vm.clearFilter }
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _vm.isRequestOngoing
                ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "custom_Card" }, [
                _c("div", { staticClass: "card card-table" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "div",
                      { staticClass: "table-responsive" },
                      [
                        _c("vuetable", {
                          ref: "vuetable",
                          attrs: {
                            "api-mode": false,
                            fields: _vm.fields,
                            "per-page": _vm.perPage,
                            "pagination-path": "pagination",
                            "sort-order": _vm.sortOrder,
                            "no-data-template": _vm.showNomessage
                              ? "No records available"
                              : "",
                            "data-manager": _vm.dataManager,
                            css: _vm.css.table
                          },
                          on: {
                            "vuetable:pagination-data": _vm.onPaginationData
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "name",
                                fn: function(props) {
                                  return [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(props.rowData.name) +
                                        "\n                    "
                                    )
                                  ]
                                }
                              },
                              {
                                key: _vm.$t("users.action"),
                                fn: function(props) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-button-container" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-white text-success mr-2",
                                            attrs: {
                                              to:
                                                "users/" +
                                                props.rowData.id +
                                                "/edit"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "far fa-edit mr-1"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("general.edit"))
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-white text-danger mr-2",
                                            on: {
                                              click: function($event) {
                                                return _vm.removeUser(
                                                  props.rowData.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "far fa-trash-alt mr-1"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("general.delete"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("vuetable-pagination", {
                          ref: "pagination",
                          attrs: { css: _vm.css.pagination },
                          on: {
                            "vuetable-pagination:change-page": _vm.onChangePage
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }