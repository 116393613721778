var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("h3", [_vm._v(_vm._s(_vm.$t("wizard.account_info")))]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.$t("wizard.account_info_desc")))]),
        _vm._v(" "),
        _vm.isRequestOnGoing
          ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            attrs: { novalidate: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.next()
              }
            }
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "form-group col-md-12",
                  attrs: {
                    label: _vm.$tc("settings.account_settings.profile_picture")
                  }
                },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$tc("general.choose_file")))]),
                  _vm._v(" "),
                  _c("div", [
                    !_vm.previewAvatar
                      ? _c("input", {
                          attrs: { type: "file", id: "fileinput" },
                          on: {
                            change: _vm.onFileChange,
                            click: function($event) {
                              return _vm.clearValue(1)
                            }
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.previewAvatar
                      ? _c("img", {
                          staticClass: "avatar-img",
                          staticStyle: { width: "100px" },
                          attrs: { src: _vm.previewAvatar, id: "previewImg" },
                          on: { click: _vm.changeFileImage, error: _vm.altImg }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "d-none",
                    attrs: { type: "file", id: "fileimageinput" },
                    on: {
                      change: _vm.onFileChange,
                      click: function($event) {
                        return _vm.clearValue(2)
                      }
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.name")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.profileData.name,
                      expression: "profileData.name",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.profileData.name.$error },
                  attrs: { type: "text", name: "name" },
                  domProps: { value: _vm.profileData.name },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.profileData,
                          "name",
                          $event.target.value.trim()
                        )
                      },
                      function($event) {
                        return _vm.$v.profileData.name.$touch()
                      }
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.$v.profileData.name.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.nameError))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.email")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.profileData.email,
                      expression: "profileData.email",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.profileData.email.$error },
                  attrs: { type: "text", name: "email" },
                  domProps: { value: _vm.profileData.email },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.profileData,
                          "email",
                          $event.target.value.trim()
                        )
                      },
                      function($event) {
                        return _vm.$v.profileData.email.$touch()
                      }
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.$v.profileData.email.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.emailError))
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.password")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.profileData.password,
                      expression: "profileData.password",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.profileData.password.$error },
                  attrs: { type: "password", name: "password" },
                  domProps: { value: _vm.profileData.password },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.profileData,
                          "password",
                          $event.target.value.trim()
                        )
                      },
                      function($event) {
                        return _vm.$v.profileData.password.$touch()
                      }
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.$v.profileData.password.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.passwordError))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("wizard.confirm_password")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.profileData.confirm_password,
                      expression: "profileData.confirm_password",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.$v.profileData.confirm_password.$error
                  },
                  attrs: { type: "password", name: "confirm_password" },
                  domProps: { value: _vm.profileData.confirm_password },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.profileData,
                          "confirm_password",
                          $event.target.value.trim()
                        )
                      },
                      function($event) {
                        return _vm.$v.profileData.confirm_password.$touch()
                      }
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.$v.profileData.confirm_password.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.confirmPasswordError))
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-4",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit"
                }
              },
              [
                _vm._v(
                  "\r\n        " +
                    _vm._s(_vm.$t("wizard.save_cont")) +
                    "\r\n      "
                )
              ]
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal custom-modal fade",
        attrs: { id: "crop_image_form", role: "dialog" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c("Cropper", {
                    ref: "cropper",
                    staticClass: "cropper",
                    attrs: {
                      src: _vm.previewImage,
                      "stencil-props": {
                        aspectRatio: 1
                      },
                      canvas: {
                        height: 400,
                        width: 400
                      }
                    },
                    on: { change: _vm.changeImage }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "submit-section" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary submit-btn",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelpopup }
                      },
                      [
                        _vm._v(
                          " \r\n                            " +
                            _vm._s(_vm.$t("general.cancel"))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary submit-btn",
                        attrs: { type: "button", disabled: _vm.isLoading },
                        on: { click: _vm.cropImage }
                      },
                      [
                        _vm.isLoading
                          ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
                          : _vm._e(),
                        _vm._v(
                          "\r\n                            " +
                            _vm._s(_vm.$t("general.save"))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "d-none",
                        attrs: {
                          type: "button",
                          "data-toggle": "modal",
                          id: "cancelpopup",
                          "data-dismiss": "modal"
                        }
                      },
                      [
                        _vm._v(
                          "\r\n                            " +
                            _vm._s(_vm.$t("general.save"))
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("button", {
                      staticClass: "d-none",
                      attrs: {
                        type: "button",
                        "data-toggle": "modal",
                        id: "showpopup",
                        "data-target": "#crop_image_form"
                      }
                    })
                  ])
                ],
                1
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Crop Image")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            id: "cancelbutton",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }