var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card-header" }, [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("h5", { staticClass: "card-title" }, [
            _vm._v(_vm._s(_vm.$t("dashboard.monthly_chart.title")))
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedYear,
                  expression: "selectedYear"
                }
              ],
              staticClass: "form-control dashboard_chart",
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedYear = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onChangeYear
                ]
              }
            },
            _vm._l(_vm.years, function(item) {
              return _c("option", { key: item, domProps: { value: item } }, [
                _vm._v(_vm._s(item))
              ])
            }),
            0
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-10" }, [
          _c(
            "div",
            [
              _c("apexchart", {
                attrs: {
                  width: "600",
                  type: "bar",
                  options: _vm.options,
                  series: _vm.series
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-lg-2", staticStyle: { "text-align": "center" } },
          [
            _c("div", { staticClass: "mb-4" }, [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.$t("dashboard.chart_info.total_sales")))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "h3 text-primary mr-5 sales_value" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.getFormattedSalesTotal) }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("span", [
                _vm._v(
                  " " + _vm._s(_vm.$t("dashboard.chart_info.total_receipts"))
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "h3 text-success mr-5 sales_value" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.getFormattedTotalReceipts) }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("dashboard.chart_info.total_expense")))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "h3 text-danger mr-5 sales_value" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.getFormattedTotalExpenses) }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("dashboard.chart_info.net_income")))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "h3 text-dark mr-5 sales_value" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.getFormattedTotalNetProfit)
                  }
                })
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }