var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invoice
    ? _c("base-page", { staticClass: "xl:pl-96" }, [
        _c(
          "div",
          { staticClass: "row justify-content-center" },
          [
            _c("div", { staticClass: "col-xl-10" }, [
              _c("div", { staticClass: "page-header" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h3", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.invoice.invoice_number))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _vm.invoice.status === "DRAFT"
                        ? _c(
                            "a",
                            {
                              staticClass: "btn sent_button mr-1",
                              on: { click: _vm.onMarkAsSent }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("invoices.mark_as_sent")) +
                                  "\n              "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.invoice.status === "DRAFT"
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-primary mr-1",
                              on: {
                                click: function($event) {
                                  return _vm.onSendInvoice(
                                    _vm.$route.params.id,
                                    _vm.invoice
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("invoices.send_invoice")) +
                                  "\n              "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.invoice.status === "SENT" ||
                      _vm.invoice.status === "OVERDUE" ||
                      _vm.invoice.status === "VIEWED"
                        ? _c(
                            "router-link",
                            {
                              staticClass: "btn btn-primary mr-1",
                              attrs: {
                                to:
                                  "/admin/payments/" +
                                  _vm.$route.params.id +
                                  "/create"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("payments.record_payment")) +
                                  "\n              "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "invoice-item" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "invoice-logo" }, [
                          _c("img", {
                            attrs: { src: "assets/img/logo.png", alt: "logo" }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("p", { staticClass: "invoice-details" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("invoices.invoice_number")))
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.invoice.invoice_number) + " "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("invoices.invoice_date")))
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.invoice.formattedInvoiceDate) + " "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("invoices.due_date")))
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.invoice.formattedDueDate) +
                              "\n                      "
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "invoice-item" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c(
                          "div",
                          { staticClass: "invoice-info invoice-info2" },
                          [
                            _c("strong", { staticClass: "customer-text" }, [
                              _vm._v("Invoice To")
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "invoice-details" }, [
                              _vm._v(
                                "\n                          " +
                                  _vm._s(
                                    _vm.invoice.user && _vm.invoice.user.name
                                  )
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                          " +
                                  _vm._s(
                                    _vm.invoice.user && _vm.invoice.user.phone
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                          " +
                                  _vm._s(
                                    _vm.invoice.user && _vm.invoice.user.email
                                  ) +
                                  " "
                              ),
                              _c("br")
                            ])
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "invoice-item invoice-table-wrap" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "table-responsive" }, [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "invoice-table table table-bordered"
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.$tc("items.item", 2)))
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "text-center" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("invoices.item.quantity"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "text-center" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("invoices.item.price"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm.invoice.discount_per_item === "YES"
                                      ? _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tc(
                                                  "estimates.item.discount"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "text-right" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("invoices.item.total"))
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.invoice.items, function(item) {
                                    return _c("tr", { key: item.id }, [
                                      _c("td", [_vm._v(_vm._s(item.name))]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(item.quantity))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                item.price,
                                                _vm.invoice.user.currency
                                              )
                                            )
                                          }
                                        })
                                      ]),
                                      _vm._v(" "),
                                      item.discount_type === "percentage" &&
                                      _vm.invoice.discount_per_item === "YES"
                                        ? _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                _vm._s(item.discount + "%")
                                              )
                                            ]
                                          )
                                        : item.discount_type === "fixed" &&
                                          _vm.invoice.discount_per_item ===
                                            "YES"
                                        ? _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$utils.formatMoney(
                                                      item.discount_val,
                                                      _vm.invoice.user.currency
                                                    )
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                item.total,
                                                _vm.invoice.user.currency
                                              )
                                            )
                                          }
                                        })
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-xl-4 ml-auto" },
                          [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                { staticClass: "invoice-table-two table" },
                                [
                                  _c(
                                    "tbody",
                                    [
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            _vm._s(_vm.$t("invoices.sub_total"))
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$utils.formatMoney(
                                                  _vm.invoice.sub_total,
                                                  _vm.invoice.user.currency
                                                )
                                              )
                                            }
                                          })
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _vm.invoice.discount
                                        ? _c("tr", [
                                            _c("th", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("invoices.discount")
                                                ) +
                                                  _vm._s(
                                                    _vm.invoice
                                                      .discount_type ===
                                                      "percentage"
                                                      ? _vm.invoice.discount +
                                                          "%"
                                                      : ""
                                                  ) +
                                                  ":"
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("span", [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$utils.formatMoney(
                                                        _vm.invoice
                                                          .discount_val,
                                                        _vm.invoice.user
                                                          .currency
                                                      )
                                                    )
                                                  }
                                                })
                                              ])
                                            ])
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(_vm.taxes, function(item, index) {
                                        return _c("tr", { key: index }, [
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(
                                                index === 0
                                                  ? _vm.$t("estimates.tax")
                                                  : ""
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "float-right" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "\n                              " +
                                                    _vm._s(item.name) +
                                                    " (" +
                                                    _vm._s(item.percent) +
                                                    "%) "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("span", [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$utils.formatMoney(
                                                        item.amount,
                                                        _vm.invoice.user
                                                          .currency
                                                      )
                                                    )
                                                  }
                                                })
                                              ])
                                            ]
                                          )
                                        ])
                                      }),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            _vm._s(_vm.$t("invoices.total"))
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _c("span", [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$utils.formatMoney(
                                                    _vm.invoice.total,
                                                    _vm.invoice.user.currency
                                                  )
                                                )
                                              }
                                            })
                                          ])
                                        ])
                                      ])
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("SendInvoiceModal", { ref: "modal" })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }