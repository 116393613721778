var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-xl-3 col-sm-6 col-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "dash-widget-header" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "dash-count" }, [
              _c("div", { staticClass: "dash-title" }, [
                _vm._v(_vm._s(_vm.$t("dashboard.cards.due_amount")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dash-counts" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$utils.formatMoney(
                        _vm.getTotalDueAmount,
                        _vm.defaultCurrency
                      )
                    )
                  }
                })
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress progress-sm mt-3" }, [
            _c("div", {
              staticClass: "progress-bar bg-5",
              style: { width: _vm.getTotalDuePercentage + "%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": _vm.getTotalDuePercentage,
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-xl-3 col-sm-6 col-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "dash-widget-header" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "dash-count" }, [
              _c("div", { staticClass: "dash-title" }, [
                _vm._v(_vm._s(_vm.$t("dashboard.cards.customers")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dash-counts" }, [
                _c("p", [_vm._v(_vm._s(_vm.getContacts))])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress progress-sm mt-3" }, [
            _c("div", {
              staticClass: "progress-bar bg-6",
              style: { width: _vm.getContacts + "%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": _vm.getContacts,
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-xl-3 col-sm-6 col-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "dash-widget-header" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "dash-count" }, [
              _c("div", { staticClass: "dash-title" }, [
                _vm._v(_vm._s(_vm.$t("dashboard.cards.invoices")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dash-counts" }, [
                _c("p", [_vm._v(_vm._s(_vm.getInvoices))])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress progress-sm mt-3" }, [
            _c("div", {
              staticClass: "progress-bar bg-7",
              style: { width: _vm.getInvoices + "%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": _vm.getInvoices,
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-xl-3 col-sm-6 col-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "dash-widget-header" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "dash-count" }, [
              _c("div", { staticClass: "dash-title" }, [
                _vm._v(_vm._s(_vm.$t("dashboard.cards.estimates")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dash-counts" }, [
                _c("p", [_vm._v(_vm._s(_vm.getEstimates))])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress progress-sm mt-3" }, [
            _c("div", {
              staticClass: "progress-bar bg-8",
              style: { width: _vm.getEstimates + "%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": _vm.getEstimates,
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "dash-widget-icon bg-1" }, [
      _c("i", { staticClass: "fas fa-dollar-sign" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "dash-widget-icon bg-2" }, [
      _c("i", { staticClass: "fas fa-users" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "dash-widget-icon bg-3" }, [
      _c("i", { staticClass: "fas fa-file-alt" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "dash-widget-icon bg-4" }, [
      _c("i", { staticClass: "far fa-file" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }