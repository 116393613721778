var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.isRequestOngoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.$t("dashboard.recent_invoices_card.title")))
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn-right btn btn-sm btn-outline-primary",
                      attrs: { to: "/admin/invoices" }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.$t("dashboard.recent_invoices_card.view_all")
                          ) +
                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c(
                "div",
                { staticClass: "progress progress-md rounded-pill mb-3" },
                [
                  _c("div", {
                    staticClass: "progress-bar bg-success",
                    style: { width: _vm.invoiceData.paidCount + "%" },
                    attrs: {
                      role: "progressbar",
                      "aria-valuenow": _vm.invoiceData.paidCount,
                      "aria-valuemin": "0",
                      "aria-valuemax": "100"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "progress-bar bg-warning",
                    style: { width: _vm.invoiceData.unPaidCount + "%" },
                    attrs: {
                      role: "progressbar",
                      "aria-valuenow": _vm.invoiceData.unPaidCount,
                      "aria-valuemin": "0",
                      "aria-valuemax": "100"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "progress-bar bg-danger",
                    style: { width: _vm.invoiceData.partiallyPaidCount + "%" },
                    attrs: {
                      role: "progressbar",
                      "aria-valuenow": _vm.invoiceData.partiallyPaidCount,
                      "aria-valuemin": "0",
                      "aria-valuemax": "100"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _vm._m(0)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-stripped table-hover" }, [
                _c("thead", { staticClass: "thead-light" }, [
                  _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("dashboard.recent_invoices_card.customer")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("dashboard.recent_invoices_card.amount_due")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("dashboard.recent_invoices_card.due_on"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("invoices.status")))]),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-right" }, [_vm._v("Action")])
                  ])
                ]),
                _vm._v(" "),
                _vm.getDueInvoices.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.getDueInvoices, function(row) {
                        return _c("tr", { key: row.id }, [
                          _c("td", [
                            _c(
                              "h2",
                              { staticClass: "table-avatar" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "avatar avatar-sm mr-2",
                                    attrs: {
                                      to: {
                                        path:
                                          "customers/" + row.user.id + "/view"
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "avatar-img rounded-circle",
                                      attrs: {
                                        src: _vm.$utils.userImage(
                                          row.user.avatar
                                        ),
                                        alt: "User Image"
                                      },
                                      on: { error: _vm.altImg }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "font-medium text-primary-500",
                                    attrs: {
                                      to: {
                                        path: "invoices/" + row.id + "/view"
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                              " +
                                        _vm._s(row.user.name)
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$utils.formatMoney(
                                    row.due_amount,
                                    row.user.currency
                                  )
                                )
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(row.formattedDueDate))]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "span",
                              {
                                style: {
                                  "background-color":
                                    "" +
                                    _vm.$utils.getBadgeStatusColor(row.status)
                                      .bgColor,
                                  color: _vm.$utils.getBadgeStatusColor(
                                    row.status
                                  ).color
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.status != "PARTIALLY_PAID"
                                        ? row.status
                                        : row.status.replace("_", " ")
                                    )
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-right" }, [
                            _c(
                              "div",
                              { staticClass: "dropdown dropdown-action" },
                              [
                                _vm._m(1, true),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-menu dropdown-menu-right"
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          to: "invoices/" + row.id + "/edit"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-edit mr-2"
                                        }),
                                        _vm._v(_vm._s(_vm.$t("general.edit")))
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          to: "invoices/" + row.id + "/view"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-eye mr-2"
                                        }),
                                        _vm._v(_vm._s(_vm.$t("invoices.view")))
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.removeInvoice(row.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-trash-alt mr-2"
                                        }),
                                        _vm._v(_vm._s(_vm.$t("general.delete")))
                                      ]
                                    ),
                                    _vm._v(" "),
                                    row.status === "DRAFT"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.sentInvoice(row.id)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "far fa-check-circle mr-2"
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("invoices.mark_as_sent")
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  : _c("tbody", [_vm._m(2)])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.$t("dashboard.recent_estimate_card.title")))
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn-right btn btn-sm btn-outline-primary",
                      attrs: { to: "/admin/estimates" }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.$t("dashboard.recent_estimate_card.view_all")
                          ) +
                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c(
                "div",
                { staticClass: "progress progress-md rounded-pill mb-3" },
                [
                  _c("div", {
                    staticClass: "progress-bar bg-success",
                    style: { width: _vm.estimatesData.sentCount + "%" },
                    attrs: {
                      role: "progressbar",
                      "aria-valuenow": _vm.estimatesData.sentCount,
                      "aria-valuemin": "0",
                      "aria-valuemax": "100"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "progress-bar bg-warning",
                    style: { width: _vm.estimatesData.draftCount + "%" },
                    attrs: {
                      role: "progressbar",
                      "aria-valuenow": _vm.estimatesData.draftCount,
                      "aria-valuemin": "0",
                      "aria-valuemax": "100"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "progress-bar bg-danger",
                    style: { width: _vm.estimatesData.acceptedCount + "%" },
                    attrs: {
                      role: "progressbar",
                      "aria-valuenow": _vm.estimatesData.acceptedCount,
                      "aria-valuemin": "0",
                      "aria-valuemax": "100"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _vm._m(3)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-hover" }, [
                _c("thead", { staticClass: "thead-light" }, [
                  _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("dashboard.recent_estimate_card.customer")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("dashboard.recent_estimate_card.date"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("dashboard.recent_estimate_card.amount_due")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("estimates.status")))]),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-right" }, [_vm._v("Action")])
                  ])
                ]),
                _vm._v(" "),
                _vm.getRecentEstimates.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.getRecentEstimates, function(row) {
                        return _c("tr", { key: row.id }, [
                          _c("td", [
                            _c(
                              "h2",
                              { staticClass: "table-avatar" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "avatar avatar-sm mr-2",
                                    attrs: {
                                      to: {
                                        path:
                                          "customers/" + row.user.id + "/view"
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "avatar-img rounded-circle",
                                      attrs: {
                                        src: _vm.$utils.userImage(
                                          row.user.avatar
                                        ),
                                        alt: "User Image"
                                      },
                                      on: { error: _vm.altImg }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "font-medium text-primary-500",
                                    attrs: {
                                      to: {
                                        path: "invoices/" + row.id + "/view"
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                              " +
                                        _vm._s(row.user.name)
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(row.formattedExpiryDate))]),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$utils.formatMoney(
                                    row.total,
                                    row.user.currency
                                  )
                                )
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "span",
                              {
                                style: {
                                  "background-color":
                                    "" +
                                    _vm.$utils.getBadgeStatusColor(row.status)
                                      .bgColor,
                                  color: _vm.$utils.getBadgeStatusColor(
                                    row.status
                                  ).color
                                }
                              },
                              [
                                _vm._v(
                                  " \n                              " +
                                    _vm._s(row.status)
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-right" }, [
                            _c(
                              "div",
                              { staticClass: "dropdown dropdown-action" },
                              [
                                _vm._m(4, true),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "dropdown-menu dropdown-menu-right"
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          to: "estimates/" + row.id + "/edit"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-edit mr-2"
                                        }),
                                        _vm._v(_vm._s(_vm.$t("general.edit")))
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.removeEstimate(row.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-trash-alt mr-2"
                                        }),
                                        _vm._v(_vm._s(_vm.$t("general.delete")))
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: {
                                          to: "estimates/" + row.id + "/view"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-eye mr-2"
                                        }),
                                        _vm._v(_vm._s(_vm.$t("general.view")))
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.convertInToinvoice(
                                              row.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-file-alt mr-2"
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "estimates.convert_to_invoice"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.onMarkAsSent(row.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "far fa-check-circle mr-2"
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("estimates.mark_as_sent")
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    row.status !== "ACCEPTED"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.onMarkAsAccepted(
                                                  row.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "far fa-check-circle mr-2"
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "estimates.mark_as_accepted"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.status !== "REJECTED"
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.onMarkAsRejected(
                                                  row.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "far fa-times-circle mr-2"
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "estimates.mark_as_rejected"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  : _c("tbody", [_vm._m(5)])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("SendEstimateModal", { ref: "modal" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-auto" }, [
        _c("i", { staticClass: "fas fa-circle text-success mr-1" }),
        _vm._v(" Paid\n\t\t\t\t\t\t\t\t\t\t\t")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-auto" }, [
        _c("i", { staticClass: "fas fa-circle text-warning mr-1" }),
        _vm._v(" Unpaid\n\t\t\t\t\t\t\t\t\t\t\t")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-auto" }, [
        _c("i", { staticClass: "fas fa-circle text-danger mr-1" }),
        _vm._v(" Partially\n\t\t\t\t\t\t\t\t\t\t\t")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "action-icon dropdown-toggle",
        attrs: {
          href: "#",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "fas fa-ellipsis-h" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "text-center" }, [
      _c("td", { attrs: { colspan: "5" } }, [_vm._v("No records available")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-auto" }, [
        _c("i", { staticClass: "fas fa-circle text-success mr-1" }),
        _vm._v(" Sent\n\t\t\t\t\t\t\t\t\t\t\t")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-auto" }, [
        _c("i", { staticClass: "fas fa-circle text-warning mr-1" }),
        _vm._v(" Draft\n\t\t\t\t\t\t\t\t\t\t\t")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-auto" }, [
        _c("i", { staticClass: "fas fa-circle text-danger mr-1" }),
        _vm._v(" Accepted\n\t\t\t\t\t\t\t\t\t\t\t")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "action-icon dropdown-toggle",
        attrs: {
          href: "#",
          "data-toggle": "dropdown",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "fas fa-ellipsis-h" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "text-center" }, [
      _c("td", { attrs: { colspan: "5" } }, [_vm._v("No records available")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }