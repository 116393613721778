export default {
  name: null,
  phone: null,
  address_street_1: null,
  address_street_2: null,
  city: null,
  state: null,
  country_id: null,
  zip: null,
  type: null,
}
