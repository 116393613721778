var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(_vm._s(_vm.$t("navigation.settings")))
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                  _vm._v(_vm._s(_vm.$t("general.home")))
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item active" }, [
              _vm._v(_vm._s(_vm.$t("navigation.settings")))
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-3 col-md-4" }, [
        _c("div", { staticClass: "widget settings-menu" }, [
          _c("ul", [
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/user-profile" }
                  },
                  [
                    _c("i", { staticClass: "far fa-user" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.account_settings.account_settings")
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/company-info" }
                  },
                  [
                    _c("i", { staticClass: "far fa-list-alt" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.menu_title.company_information")
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/customization" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-edit" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.customization"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/preferences" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-cog" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.menu_title.preferences")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/notifications" }
                  },
                  [
                    _c("i", { staticClass: "far fa-bell" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.notifications"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/tax-types" }
                  },
                  [
                    _c("i", { staticClass: "far fa-check-square" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.menu_title.tax_types")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/payment-mode" }
                  },
                  [
                    _c("i", { staticClass: "far fa-credit-card" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.payment_modes"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/custom-fields" }
                  },
                  [
                    _c("i", { staticClass: "far fa-bell" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.custom_fields"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/expense-category" }
                  },
                  [
                    _c("i", { staticClass: "far fa-list-alt" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.expense_category"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/mail-configuration" }
                  },
                  [
                    _c("i", { staticClass: "far fa-check-square" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.mail.mail_config")))
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-9 col-md-8 mt-3" },
        [
          _vm.isRequestOnGoing
            ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "card card-body" }, [
            _c(
              "ul",
              { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
              [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link active",
                      attrs: {
                        "data-toggle": "tab",
                        href: "#tabs-1",
                        role: "tab"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.invoices.title"))
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        "data-toggle": "tab",
                        href: "#tabs-2",
                        role: "tab"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.estimates.title"))
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        "data-toggle": "tab",
                        href: "#tabs-3",
                        role: "tab"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.payments.title"))
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        "data-toggle": "tab",
                        href: "#tabs-4",
                        role: "tab"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.items.title"))
                      )
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tab-content" }, [
              _c(
                "div",
                {
                  staticClass: "tab-pane active",
                  attrs: { id: "tabs-1", role: "tabpanel" }
                },
                [_c("invoices-tab", { attrs: { settings: _vm.settings } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane",
                  attrs: { id: "tabs-2", role: "tabpanel" }
                },
                [_c("estimates-tab", { attrs: { settings: _vm.settings } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane",
                  attrs: { id: "tabs-3", role: "tabpanel" }
                },
                [_c("payments-tab", { attrs: { settings: _vm.settings } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-pane",
                  attrs: { id: "tabs-4", role: "tabpanel" }
                },
                [_c("items-tab")],
                1
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }