var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.payment
    ? _c("base-page", { staticClass: "xl:pl-96" }, [
        _c(
          "div",
          { staticClass: "row justify-content-center" },
          [
            _c("div", { staticClass: "col-xl-10" }, [
              _c("div", { staticClass: "page-header" }, [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h3", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.payment.payment_number))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-auto" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary mr-1",
                        on: {
                          click: function($event) {
                            return _vm.onPaymentSend(
                              _vm.$route.params.id,
                              _vm.payment
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.send_payment_receipt")) +
                            "\n                "
                        )
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "invoice-item" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "invoice-logo" }, [
                          _c("img", {
                            attrs: { src: "assets/img/logo.png", alt: "logo" }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6 text-right" }, [
                        _c("p", { staticClass: "invoice-details" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("payments.payment_number")))
                          ]),
                          _vm._v(_vm._s(_vm.payment.payment_number)),
                          _c("br"),
                          _vm._v(" "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("payments.date")))
                          ]),
                          _vm._v(_vm._s(_vm.payment.formattedPaymentDate)),
                          _c("br"),
                          _vm._v(" "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("invoices.title")))
                          ]),
                          _vm._v(
                            _vm._s(
                              (_vm.payment.invoice &&
                                _vm.payment.invoice.invoice_number) ||
                                "-"
                            ) + "\n                        "
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "invoice-item" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "invoice-info" }, [
                          _c("strong", { staticClass: "customer-text" }, [
                            _vm._v(_vm._s(_vm.$t("payments.payment_mode")))
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "invoice-details invoice-details-two"
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.payment.payment_method &&
                                      _vm.payment.payment_method.name
                                  )
                              ),
                              _c("br")
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c(
                          "div",
                          { staticClass: "invoice-info invoice-info2" },
                          [
                            _c("strong", { staticClass: "customer-text" }, [
                              _vm._v("Invoice To")
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "invoice-details" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.payment.user.name)
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.payment.user && _vm.payment.user.email
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.payment.user && _vm.payment.user.phone
                                  ) +
                                  " "
                              ),
                              _c("br")
                            ])
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "invoice-item invoice-table-wrap" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-xl-4 ml-auto" },
                          [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                { staticClass: "invoice-table-two table" },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("invoices.total")))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                _vm.payment.amount,
                                                _vm.payment.user.currency
                                              )
                                            )
                                          }
                                        })
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("SendPaymentModal", { ref: "modal" })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }