var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    [
      _c("div", { staticClass: "page-header" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("navigation.settings")))
            ]),
            _vm._v(" "),
            _c("ul", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                    _vm._v(_vm._s(_vm.$t("general.home")))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("li", { staticClass: "breadcrumb-item active" }, [
                _vm._v(_vm._s(_vm.$t("navigation.settings")))
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-3 col-md-4" }, [
          _c("div", { staticClass: "widget settings-menu" }, [
            _c("ul", [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/user-profile" }
                    },
                    [
                      _c("i", { staticClass: "far fa-user" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("settings.account_settings.account_settings")
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/company-info" }
                    },
                    [
                      _c("i", { staticClass: "far fa-list-alt" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("settings.menu_title.company_information")
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/customization" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-edit" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.customization"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/preferences" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-cog" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.preferences"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/notifications" }
                    },
                    [
                      _c("i", { staticClass: "far fa-bell" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.notifications"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/tax-types" }
                    },
                    [
                      _c("i", { staticClass: "far fa-check-square" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("settings.menu_title.tax_types")))
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/payment-mode" }
                    },
                    [
                      _c("i", { staticClass: "far fa-credit-card" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.payment_modes"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/custom-fields" }
                    },
                    [
                      _c("i", { staticClass: "far fa-bell" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.custom_fields"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/expense-category" }
                    },
                    [
                      _c("i", { staticClass: "far fa-list-alt" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.menu_title.expense_category"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/settings/mail-configuration" }
                    },
                    [
                      _c("i", { staticClass: "far fa-check-square" }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("settings.mail.mail_config")))
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xl-9 col-md-8 mt-3" },
          [
            _vm.isRequestOnGoing
              ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "card card-table" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h5", { staticClass: "card-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "settings.customization.payments.payment_modes"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.customization.payments.description")
                        ) + "\n\n"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-auto" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-outline-primary btn-sm",
                        attrs: {
                          href: "javascript:void(0);",
                          "data-toggle": "modal",
                          "data-target": "#add_category"
                        },
                        on: { click: _vm.addPaymentMode }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "settings.customization.payments.add_payment_mode"
                            )
                          )
                        )
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  { staticClass: "table-responsive" },
                  [
                    _c("vuetable", {
                      ref: "vuetable",
                      attrs: {
                        "api-mode": false,
                        fields: _vm.fields,
                        "per-page": _vm.perPage,
                        "pagination-path": "pagination",
                        "sort-order": _vm.sortOrder,
                        "no-data-template": _vm.showNomessage
                          ? "No records available"
                          : "",
                        "data-manager": _vm.dataManager,
                        css: _vm.css.table
                      },
                      on: { "vuetable:pagination-data": _vm.onPaginationData },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "name",
                            fn: function(props) {
                              return [
                                _c("span", [_vm._v(_vm._s(props.rowData.name))])
                              ]
                            }
                          },
                          {
                            key: _vm.$t("settings.tax_types.action"),
                            fn: function(props) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "table-button-container" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-white text-success mr-2",
                                        on: {
                                          click: function($event) {
                                            return _vm.editPaymentMode(
                                              props.rowData
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-edit mr-1"
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("general.edit"))
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-white text-danger mr-2",
                                        on: {
                                          click: function($event) {
                                            return _vm.removePaymentMode(
                                              props.rowData.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "far fa-trash-alt mr-1"
                                        }),
                                        _vm._v(_vm._s(_vm.$t("general.delete")))
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _vm._v(" "),
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("PaymentModeModal", {
        ref: "modal",
        on: { paymentadded: _vm.paymentadded }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }