var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.saveEmailConfig.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-2" }, [
        _c("div", { staticClass: "row form-group" }, [
          _c("label", { staticClass: "col-sm-3 col-form-label input-label" }, [
            _vm._v(_vm._s(_vm.$t("settings.mail.driver")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-9" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mailConfigData.mail_driver,
                    expression: "mailConfigData.mail_driver"
                  }
                ],
                staticClass: "select form-control",
                class: {
                  "is-invalid": _vm.$v.mailConfigData.mail_driver.$error
                },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_driver",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.mailDrivers, function(item) {
                return _c("option", { key: item, domProps: { value: item } }, [
                  _vm._v(_vm._s(item))
                ])
              }),
              0
            ),
            _vm._v(" "),
            _vm.$v.mailConfigData.mail_driver.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v(_vm._s(_vm.driverError))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row form-group" }, [
          _c("label", { staticClass: "col-sm-3 col-form-label input-label" }, [
            _vm._v(_vm._s(_vm.$t("settings.mail.mailgun_domain")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.mailConfigData.mail_mailgun_domain,
                  expression: "mailConfigData.mail_mailgun_domain",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "mt-2 form-control",
              class: {
                "is-invalid": _vm.$v.mailConfigData.mail_mailgun_domain.$error
              },
              attrs: { type: "text", name: "mailgun_domain" },
              domProps: { value: _vm.mailConfigData.mail_mailgun_domain },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_mailgun_domain",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    return _vm.$v.mailConfigData.mail_mailgun_domain.$touch()
                  }
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.mailConfigData.mail_mailgun_domain.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v(_vm._s(_vm.domainError))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row form-group" }, [
          _c("label", { staticClass: "col-sm-3 col-form-label input-label" }, [
            _vm._v(_vm._s(_vm.$t("settings.mail.mailgun_secret")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.mailConfigData.mail_mailgun_secret,
                  expression: "mailConfigData.mail_mailgun_secret",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "mt-2 form-control",
              class: {
                "is-invalid": _vm.$v.mailConfigData.mail_mailgun_secret.$error
              },
              attrs: {
                type: _vm.getInputType,
                name: "mailgun_secret",
                id: "mail_gun_secret"
              },
              domProps: { value: _vm.mailConfigData.mail_mailgun_secret },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_mailgun_secret",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    _vm.$v.mailConfigData.mail_mailgun_secret.$touch()
                    _vm.mailgun(1)
                  }
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.isShowPassword
              ? _c("span", {
                  staticClass: "fas fa-eye toggle-password",
                  on: {
                    click: function($event) {
                      _vm.isShowPassword = !_vm.isShowPassword
                    }
                  }
                })
              : _c("span", {
                  staticClass: "fas fa-eye-slash toggle-password",
                  on: {
                    click: function($event) {
                      _vm.isShowPassword = !_vm.isShowPassword
                    }
                  }
                }),
            _vm._v(" "),
            _vm.$v.mailConfigData.mail_mailgun_secret.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v(_vm._s(_vm.secretError))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row form-group" }, [
          _c("label", { staticClass: "col-sm-3 col-form-label input-label" }, [
            _vm._v(_vm._s(_vm.$t("settings.mail.mailgun_endpoint")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.mailConfigData.mail_mailgun_endpoint,
                  expression: "mailConfigData.mail_mailgun_endpoint",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "mt-2 form-control",
              class: {
                "is-invalid": _vm.$v.mailConfigData.mail_mailgun_endpoint.$error
              },
              attrs: { type: "text", name: "mailgun_endpoint" },
              domProps: { value: _vm.mailConfigData.mail_mailgun_endpoint },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_mailgun_endpoint",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    return _vm.$v.mailConfigData.mail_mailgun_endpoint.$touch()
                  }
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.mailConfigData.mail_mailgun_endpoint.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v(_vm._s(_vm.endpointError))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row form-group" }, [
          _c("label", { staticClass: "col-sm-3 col-form-label input-label" }, [
            _vm._v(_vm._s(_vm.$t("settings.mail.from_mail")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-9" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.mailConfigData.from_mail,
                  expression: "mailConfigData.from_mail",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "mt-2 form-control",
              class: { "is-invalid": _vm.$v.mailConfigData.from_mail.$error },
              attrs: { type: "text", name: "from_mail" },
              domProps: { value: _vm.mailConfigData.from_mail },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.mailConfigData,
                      "from_mail",
                      $event.target.value.trim()
                    )
                  },
                  function($event) {
                    return _vm.$v.mailConfigData.from_mail.$touch()
                  }
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.mailConfigData.from_mail.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  _vm._v(_vm._s(_vm.fromEmailError))
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row form-group" }, [
        _c("label", { staticClass: "col-sm-3 col-form-label input-label" }, [
          _vm._v(_vm._s(_vm.$t("settings.mail.from_name")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-9" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.mailConfigData.from_name,
                expression: "mailConfigData.from_name",
                modifiers: { trim: true }
              }
            ],
            staticClass: "mt-2 form-control",
            class: { "is-invalid": _vm.$v.mailConfigData.from_name.$error },
            attrs: { type: "text", name: "from_name" },
            domProps: { value: _vm.mailConfigData.from_name },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.mailConfigData,
                    "from_name",
                    $event.target.value.trim()
                  )
                },
                function($event) {
                  return _vm.$v.mailConfigData.from_name.$touch()
                }
              ],
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.mailConfigData.from_name.$error
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.fromNameError))
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex my-10" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                disabled: _vm.loading,
                loading: _vm.loading,
                type: "submit"
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.save")) + "\n    ")]
          ),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }