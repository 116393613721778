var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { id: "loginForm" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "form-control-label" }, [
          _vm._v(_vm._s(_vm.$t("login.email")))
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.loginData.email,
              expression: "loginData.email"
            }
          ],
          staticClass: "form-control",
          class: { "is-invalid": _vm.$v.loginData.email.$error },
          attrs: { type: "email", focus: "" },
          domProps: { value: _vm.loginData.email },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.loginData, "email", $event.target.value)
              },
              function($event) {
                return _vm.$v.loginData.email.$touch()
              }
            ]
          }
        }),
        _vm._v(" "),
        _vm.$v.loginData.email.$error
          ? _c("div", { staticClass: "invalid-feedback" }, [
              _vm._v(_vm._s(_vm.emailError))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "form-control-label" }, [
          _vm._v(_vm._s(_vm.$t("login.password")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pass-group" }, [
          _vm.getInputType === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginData.password,
                    expression: "loginData.password"
                  }
                ],
                staticClass: "form-control pass-input",
                class: { "is-invalid": _vm.$v.loginData.password.$error },
                attrs: { id: "password", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.loginData.password)
                    ? _vm._i(_vm.loginData.password, null) > -1
                    : _vm.loginData.password
                },
                on: {
                  input: function($event) {
                    return _vm.$v.loginData.password.$touch()
                  },
                  change: function($event) {
                    var $$a = _vm.loginData.password,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.loginData, "password", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.loginData,
                            "password",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.loginData, "password", $$c)
                    }
                  }
                }
              })
            : _vm.getInputType === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginData.password,
                    expression: "loginData.password"
                  }
                ],
                staticClass: "form-control pass-input",
                class: { "is-invalid": _vm.$v.loginData.password.$error },
                attrs: { id: "password", type: "radio" },
                domProps: { checked: _vm._q(_vm.loginData.password, null) },
                on: {
                  input: function($event) {
                    return _vm.$v.loginData.password.$touch()
                  },
                  change: function($event) {
                    return _vm.$set(_vm.loginData, "password", null)
                  }
                }
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginData.password,
                    expression: "loginData.password"
                  }
                ],
                staticClass: "form-control pass-input",
                class: { "is-invalid": _vm.$v.loginData.password.$error },
                attrs: { id: "password", type: _vm.getInputType },
                domProps: { value: _vm.loginData.password },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.loginData, "password", $event.target.value)
                    },
                    function($event) {
                      return _vm.$v.loginData.password.$touch()
                    }
                  ]
                }
              }),
          _vm._v(" "),
          _vm.isShowPassword
            ? _c("span", {
                staticClass: "fas fa-eye toggle-password",
                on: {
                  click: function($event) {
                    _vm.isShowPassword = !_vm.isShowPassword
                  }
                }
              })
            : _c("span", {
                staticClass: "fas fa-eye-slash toggle-password",
                on: {
                  click: function($event) {
                    _vm.isShowPassword = !_vm.isShowPassword
                  }
                }
              }),
          _vm._v(" "),
          _vm.$v.loginData.password.$error
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.passwordError))
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "custom-control custom-checkbox" }, [
              _c("input", {
                staticStyle: { "margin-left": "-24px" },
                attrs: {
                  type: "checkbox",
                  value: "lsRememberMe",
                  id: "rememberMe"
                },
                on: {
                  click: function($event) {
                    return _vm.lsRememberMe()
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "cb1" } }, [_vm._v("Remember me")])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6 text-right" },
            [
              _c(
                "router-link",
                {
                  staticClass: "forgot-link",
                  attrs: { to: "/forgot-password" }
                },
                [_vm._v("Forgot Password ?")]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-lg btn-block btn-primary",
          attrs: { disabled: _vm.isLoading, type: "submit" }
        },
        [
          _vm.isLoading
            ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.$t("login.login")) + " ")
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }