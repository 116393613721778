var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", [
    _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(_vm._s(_vm.$t("navigation.settings")))
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                  _vm._v(_vm._s(_vm.$t("general.home")))
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item active" }, [
              _vm._v(_vm._s(_vm.$t("navigation.settings")))
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xl-3 col-md-4" }, [
        _c("div", { staticClass: "widget settings-menu" }, [
          _c("ul", [
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/user-profile" }
                  },
                  [
                    _c("i", { staticClass: "far fa-user" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.account_settings.account_settings")
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/company-info" }
                  },
                  [
                    _c("i", { staticClass: "far fa-list-alt" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.menu_title.company_information")
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/customization" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-edit" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.customization"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/preferences" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-cog" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.menu_title.preferences")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/notifications" }
                  },
                  [
                    _c("i", { staticClass: "far fa-bell" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.notifications"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/tax-types" }
                  },
                  [
                    _c("i", { staticClass: "far fa-check-square" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.menu_title.tax_types")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/payment-mode" }
                  },
                  [
                    _c("i", { staticClass: "far fa-credit-card" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.payment_modes"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/custom-fields" }
                  },
                  [
                    _c("i", { staticClass: "far fa-bell" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.custom_fields"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/expense-category" }
                  },
                  [
                    _c("i", { staticClass: "far fa-list-alt" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.menu_title.expense_category"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    attrs: { to: "/admin/settings/mail-configuration" }
                  },
                  [
                    _c("i", { staticClass: "far fa-check-square" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.mail.mail_config")))
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-9 col-md-8 mt-3" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h5", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.$t("settings.company_info.company_info")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.updateCompanyData.apply(null, arguments)
                  }
                }
              },
              [
                _vm.isRequestOnGoing
                  ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "name" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$tc("settings.company_info.company_logo"))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("div", [
                      !_vm.previewLogo
                        ? _c("input", {
                            attrs: { type: "file", id: "fileinput" },
                            on: {
                              change: _vm.onFileChange,
                              click: function($event) {
                                return _vm.clearValue(1)
                              }
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm.previewLogo
                        ? _c("img", {
                            staticClass: "avatar-img",
                            staticStyle: { width: "100px" },
                            attrs: { src: _vm.previewLogo, id: "previewImg" },
                            on: {
                              click: _vm.changeFileImage,
                              error: _vm.altImg
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "d-none",
                      attrs: { type: "file", id: "fileimageinput" },
                      on: {
                        change: _vm.onFileChange,
                        click: function($event) {
                          return _vm.clearValue(2)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "name" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$tc("settings.company_info.company_name")) +
                          " "
                      ),
                      _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.name,
                          expression: "formData.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "name",
                        placeholder: _vm.$t(
                          "settings.company_info.company_name"
                        )
                      },
                      domProps: { value: _vm.formData.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.formData, "name", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "email" }
                    },
                    [_vm._v(_vm._s(_vm.$tc("settings.company_info.phone")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.phone,
                          expression: "formData.phone"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "phone",
                        placeholder: "+918943594895"
                      },
                      domProps: { value: _vm.formData.phone },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.formData, "phone", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-lg-3 col-form-label" }, [
                    _vm._v(
                      _vm._s(_vm.$tc("settings.company_info.country")) + " "
                    ),
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-9" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.country,
                            expression: "country"
                          }
                        ],
                        staticClass: "select form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.country = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", [_vm._v("Select")]),
                        _vm._v(" "),
                        _vm._l(_vm.countries, function(item) {
                          return _c(
                            "option",
                            { key: item.id, domProps: { value: item.id } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "email" }
                    },
                    [_vm._v(_vm._s(_vm.$tc("settings.company_info.state")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.state,
                          expression: "formData.state"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "state",
                        placeholder: "State"
                      },
                      domProps: { value: _vm.formData.state },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.formData, "state", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "email" }
                    },
                    [_vm._v(_vm._s(_vm.$tc("settings.company_info.city")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.city,
                          expression: "formData.city"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "city",
                        placeholder: "Islamabad"
                      },
                      domProps: { value: _vm.formData.city },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.formData, "city", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "email" }
                    },
                    [_vm._v(_vm._s(_vm.$tc("settings.company_info.zip")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.zip,
                          expression: "formData.zip"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "Zip", placeholder: "Zip" },
                      domProps: { value: _vm.formData.zip },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.formData, "zip", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 col-form-label input-label",
                      attrs: { for: "email" }
                    },
                    [_vm._v(_vm._s(_vm.$tc("settings.company_info.address")))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.address_street_1,
                          expression: "formData.address_street_1"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        rows: "3",
                        columns: "5",
                        id: "Address",
                        placeholder: _vm.$tc("general.street_1")
                      },
                      domProps: { value: _vm.formData.address_street_1 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formData,
                            "address_street_1",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row form-group" }, [
                  _c("label", {
                    staticClass: "col-sm-3 col-form-label input-label",
                    attrs: { for: "email" }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.address_street_2,
                          expression: "formData.address_street_2"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        rows: "3",
                        columns: "5",
                        id: "Address2",
                        placeholder: _vm.$tc("general.street_2")
                      },
                      domProps: { value: _vm.formData.address_street_2 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formData,
                            "address_street_2",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "submit",
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading
                      }
                    },
                    [
                      !_vm.isLoading
                        ? _c("i", {
                            attrs: { className: "fa fa-refresh fa-spin" }
                          })
                        : _vm._e(),
                      _vm._v(
                        " " + _vm._s(_vm.$tc("settings.company_info.save"))
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal custom-modal fade",
          attrs: { id: "crop_image_form", role: "dialog" }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v("Crop Image")
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        id: "cancelbutton",
                        "aria-label": "Close"
                      }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("×")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _c("cropper", {
                      ref: "cropper",
                      staticClass: "cropper",
                      attrs: { src: _vm.previewImage },
                      on: { change: _vm.changeImage }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "submit-section" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary submit-btn",
                          on: { click: _vm.cancelPopup }
                        },
                        [
                          _vm._v(
                            " \n                           " +
                              _vm._s(_vm.$t("general.cancel"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary submit-btn",
                          on: { click: _vm.cropImage }
                        },
                        [
                          _vm.isLoading
                            ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
                            : _vm._e(),
                          _vm._v(
                            "\n                           " +
                              _vm._s(_vm.$t("general.save"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "d-none",
                          attrs: {
                            "data-toggle": "modal",
                            id: "cancelpopup",
                            "data-dismiss": "modal"
                          }
                        },
                        [
                          _vm._v(
                            "\n                           " +
                              _vm._s(_vm.$t("general.save"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "d-none",
                          attrs: {
                            "data-toggle": "modal",
                            id: "showpopup",
                            "data-target": "#crop_image_form"
                          }
                        },
                        [
                          _vm._v(
                            "\n                           " +
                              _vm._s(_vm.$t("general.save"))
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }