var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v(_vm._s(_vm.$t("wizard.company_info")))]),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.$t("wizard.company_info_desc")))]),
      _vm._v(" "),
      _vm.isFetching
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.next()
            }
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "row mt-4" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$tc("settings.company_info.company_logo")))
                ]),
                _vm._v(" "),
                _c("div", { attrs: { id: "logo-box" } }, [
                  _c("div", [
                    !_vm.previewLogo
                      ? _c("input", {
                          attrs: { type: "file", id: "fileinput" },
                          on: {
                            change: _vm.onFileChange,
                            click: function($event) {
                              return _vm.clearValue(1)
                            }
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.previewLogo
                      ? _c("img", {
                          staticClass: "avatar-img",
                          staticStyle: { width: "100px" },
                          attrs: { src: _vm.previewLogo, id: "previewImg" },
                          on: { click: _vm.changeFileImage, error: _vm.altImg }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "d-none",
                    attrs: { type: "file", id: "fileimageinput" },
                    on: {
                      change: _vm.onFileChange,
                      click: function($event) {
                        return _vm.clearValue(2)
                      }
                    }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.company_name")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.companyData.name,
                      expression: "companyData.name",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.companyData.name.$error },
                  attrs: { type: "text", name: "name" },
                  domProps: { value: _vm.companyData.name },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.companyData,
                          "name",
                          $event.target.value.trim()
                        )
                      },
                      function($event) {
                        return _vm.$v.companyData.name.$touch()
                      }
                    ],
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.$v.companyData.name.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.companyNameError))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.country")))]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.country,
                        expression: "country"
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      "is-invalid": _vm.$v.companyData.country_id.$error
                    },
                    attrs: {
                      placeholder: _vm.$t("general.select_country"),
                      "track-by": "id",
                      label: "name"
                    },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.country = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(_vm.countries, function(country) {
                    return _c(
                      "option",
                      { key: country.id, domProps: { value: country.id } },
                      [_vm._v(_vm._s(country.name))]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.$v.companyData.country_id.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(_vm._s(_vm.countryError))
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.state")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.companyData.state,
                      expression: "companyData.state"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { name: "state", type: "text" },
                  domProps: { value: _vm.companyData.state },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.companyData, "state", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("wizard.city")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.companyData.city,
                      expression: "companyData.city"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { name: "city", type: "text" },
                  domProps: { value: _vm.companyData.city },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.companyData, "city", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("wizard.address")))]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.companyData.address_street_1,
                        expression: "companyData.address_street_1",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: _vm.$t("general.street_1"),
                      name: "billing_street1",
                      rows: "2"
                    },
                    domProps: { value: _vm.companyData.address_street_1 },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.companyData,
                            "address_street_1",
                            $event.target.value.trim()
                          )
                        },
                        function($event) {
                          return _vm.$v.companyData.address_street_1.$touch()
                        }
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("label"),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.companyData.address_street_2,
                        expression: "companyData.address_street_2"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      invalid: _vm.$v.companyData.address_street_2.$error,
                      placeholder: _vm.$t("general.street_2"),
                      name: "billing_street2",
                      rows: "2"
                    },
                    domProps: { value: _vm.companyData.address_street_2 },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.companyData,
                            "address_street_2",
                            $event.target.value
                          )
                        },
                        function($event) {
                          return _vm.$v.companyData.address_street_2.$touch()
                        }
                      ]
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("wizard.zip_code")))]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.companyData.zip,
                        expression: "companyData.zip",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "zip" },
                    domProps: { value: _vm.companyData.zip },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.companyData,
                          "zip",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col-md-6" }, [
                  _c("label", [_vm._v(_vm._s(_vm.$t("wizard.phone")))]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.companyData.phone,
                        expression: "companyData.phone",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "phone" },
                    domProps: { value: _vm.companyData.phone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.companyData,
                          "phone",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal custom-modal fade",
                  attrs: { id: "crop_image_form", role: "dialog" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal-dialog modal-dialog-centered",
                      attrs: { role: "document" }
                    },
                    [
                      _c("div", { staticClass: "modal-content" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal-body" },
                          [
                            _c("cropper", {
                              ref: "cropper",
                              staticClass: "cropper",
                              attrs: { src: _vm.previewImage },
                              on: { change: _vm.changeImage }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "submit-section" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary submit-btn",
                                  attrs: { type: "button" },
                                  on: { click: _vm.cancelPopup }
                                },
                                [
                                  _vm._v(
                                    " \n                          " +
                                      _vm._s(_vm.$t("general.cancel"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary submit-btn",
                                  attrs: { type: "button" },
                                  on: { click: _vm.cropImage }
                                },
                                [
                                  _vm.isLoading
                                    ? _c("i", {
                                        staticClass: "fas fa-spinner fa-spin"
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                          " +
                                      _vm._s(_vm.$t("general.save"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "d-none",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "modal",
                                    id: "cancelpopup",
                                    "data-dismiss": "modal"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                          " +
                                      _vm._s(_vm.$t("general.save"))
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "d-none",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "modal",
                                    id: "showpopup",
                                    "data-target": "#crop_image_form"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                          " +
                                      _vm._s(_vm.$t("general.save"))
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mt-4",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  variant: "primary",
                  type: "submit"
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("wizard.save_cont")) + "\n      "
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Crop Image")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            id: "cancelbutton",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }