var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", [
    _c("div", { staticClass: "row align-items-center" }, [
      _c("div", { staticClass: "col" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$t("items.new_item")))
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                _vm._v(_vm._s(_vm.$t("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/admin/items" } }, [
                _vm._v(_vm._s(_vm.$tc("items.item", 2)))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.$route.name === "items.edit"
            ? _c("li", { staticClass: "breadcrumb-item" }, [
                _vm._v(_vm._s(_vm.$t("items.edit_item")))
              ])
            : _c("li", { staticClass: "breadcrumb-item" }, [
                _vm._v(_vm._s(_vm.$t("items.new_item")))
              ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _vm.isRequestOnGoing
            ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.$tc("items.item", 2)))
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitItem.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("items.name")) + " "),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v("*")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.formData.name,
                              expression: "formData.name",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.$v.formData.name.$error },
                          attrs: { type: "text", autocomplete: "off" },
                          domProps: { value: _vm.formData.name },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formData,
                                  "name",
                                  $event.target.value.trim()
                                )
                              },
                              function($event) {
                                return _vm.$v.formData.name.$touch()
                              }
                            ],
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.$v.formData.name.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v(_vm._s(_vm.nameError))
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("items.price")) + " "),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v("*")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group" }, [
                          _c("span"),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.price,
                                expression: "price",
                                modifiers: { trim: true }
                              },
                              {
                                name: "money",
                                rawName: "v-money",
                                value: _vm.money,
                                expression: "money"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.$v.formData.price.$error
                            },
                            attrs: { type: "text", autocomplete: "off" },
                            domProps: { value: _vm.price },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.price = $event.target.value.trim()
                                },
                                function($event) {
                                  return _vm.$v.formData.price.$touch()
                                }
                              ],
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.priceError
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(_vm._s(_vm.priceError))
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("items.select_a_unit")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.unit,
                                expression: "formData.unit"
                              }
                            ],
                            staticClass: "select form-control",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.formData,
                                  "unit",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", [_vm._v("Select")]),
                            _vm._v(" "),
                            _vm._l(_vm.itemUnits, function(item) {
                              return _c(
                                "option",
                                { key: item.id, domProps: { value: item.id } },
                                [_vm._v(_vm._s(item.name))]
                              )
                            })
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("items.description")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.formData.description,
                              expression: "formData.description",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.$v.formData.description.$error
                          },
                          attrs: { type: "text", autocomplete: "off" },
                          domProps: { value: _vm.formData.description },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formData,
                                  "description",
                                  $event.target.value.trim()
                                )
                              },
                              function($event) {
                                return _vm.$v.formData.description.$touch()
                              }
                            ],
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.$v.formData.description.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v(_vm._s(_vm.descriptionError))
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            type: "submit"
                          }
                        },
                        [
                          _vm.isLoading
                            ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("items.update_item")
                                  : _vm.$t("items.save_item")
                              )
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }