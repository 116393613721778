var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-body" }, [
    _c("h5", { staticClass: "basic_info mt-4" }, [
      _vm._v(_vm._s(_vm.$t("customers.basic_info")))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-3" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("customers.display_name")))]),
        _vm._v(" "),
        _c("p", { staticClass: "h6 text-black" }, [
          _vm._v(
            _vm._s(
              _vm.selectedViewCustomer.customer &&
                _vm.selectedViewCustomer.customer.name
                ? _vm.selectedViewCustomer.customer.name
                : ""
            )
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("customers.phone_number")))]),
        _vm._v(" "),
        _c("p", { staticClass: "h6 text-black" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.selectedViewCustomer.customer &&
                  _vm.selectedViewCustomer.customer.phone
                  ? _vm.selectedViewCustomer.customer.phone
                  : ""
              )
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("customers.email")))]),
        _vm._v(" "),
        _c("p", { staticClass: "h6 text-black" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.selectedViewCustomer.customer &&
                  _vm.selectedViewCustomer.customer.email
                  ? _vm.selectedViewCustomer.customer.email
                  : ""
              )
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }