var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", [
    _c(
      "div",
      { staticClass: "content container-fluid" },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col" }, [
              _c("h3", { staticClass: "page-title" }, [
                _vm._v(_vm._s(_vm.$tc("estimates.estimate")))
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
                      _vm._v(_vm._s(_vm.$t("general.home")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(_vm._s(_vm.$tc("estimates.estimate")))
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary mr-1",
                    attrs: { to: "/admin/estimates/create" }
                  },
                  [_c("i", { staticClass: "fas fa-plus" })]
                ),
                _vm._v(" "),
                _vm.totalEstimates
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary filter-btn",
                        attrs: { id: "filter_search" },
                        on: {
                          click: function($event) {
                            return _vm.toggleFilter(1)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-filter" })]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters"
              }
            ],
            staticClass: "card filter-card"
          },
          [
            _c("div", { staticClass: "card-body pb-0" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$tc("customers.customer", 1)))
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.customer,
                            expression: "filters.customer"
                          }
                        ],
                        staticClass: "select form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filters,
                              "customer",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", [_vm._v("Select Customer")]),
                        _vm._v(" "),
                        _vm._l(_vm.customers, function(item) {
                          return _c(
                            "option",
                            {
                              key: item.id,
                              domProps: {
                                value: { id: item.id, name: item.id }
                              }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("estimates.status")))]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.status,
                            expression: "filters.status"
                          }
                        ],
                        staticClass: "select form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filters,
                              "status",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", [
                          _vm._v(_vm._s(_vm.$t("general.select_a_status")))
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.status, function(item, index) {
                          return _c("option", { key: index }, [
                            _vm._v(_vm._s(item))
                          ])
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("estimates.estimate_number")))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.estimate_number,
                          expression: "filters.estimate_number"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.filters.estimate_number },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.filters,
                            "estimate_number",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.from")))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "datemx" },
                      [
                        _c("datepicker", {
                          attrs: {
                            "disabled-date": _vm.$utils.disabledAfterToday,
                            "value-type": "format",
                            format: "YYYY-MM-DD"
                          },
                          model: {
                            value: _vm.filters.from_date,
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "from_date", $$v)
                            },
                            expression: "filters.from_date"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.to")))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "datemx" },
                      [
                        _c("datepicker", {
                          attrs: {
                            "disabled-date": _vm.$utils.disabledAfterToday,
                            "value-type": "format",
                            format: "YYYY-MM-DD"
                          },
                          model: {
                            value: _vm.filters.to_date,
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "to_date", $$v)
                            },
                            expression: "filters.to_date"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass:
                  "absolute text-sm leading-snug text-black cursor-pointer text-danger",
                staticStyle: {
                  top: "10px",
                  right: "15px",
                  position: "absolute"
                },
                on: { click: _vm.clearFilter }
              },
              [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row mt-4" },
          [
            _vm.isRequestOngoing
              ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12" }, [
              _c("ul", { staticClass: "nav nav-tabs ml-1" }, [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.filters.status === "DRAFT" },
                      on: {
                        click: function($event) {
                          _vm.filters.status = "DRAFT"
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.draft")))]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: { active: _vm.filters.status === "SENT" },
                      on: {
                        click: function($event) {
                          _vm.filters.status = "SENT"
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.sent")))]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: {
                        active:
                          _vm.filters.status !== "SENT" &&
                          _vm.filters.status !== "DRAFT"
                      },
                      on: {
                        click: function($event) {
                          _vm.filters.status = ""
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.all")))]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", { staticClass: "custom_Card" }, [
                    _c("div", { staticClass: "card card-table" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _c(
                          "div",
                          { staticClass: "table-responsive" },
                          [
                            _c("vuetable", {
                              ref: "vuetable",
                              attrs: {
                                "api-mode": false,
                                fields: _vm.fields,
                                "per-page": _vm.perPage,
                                "pagination-path": "pagination",
                                "sort-order": _vm.sortOrder,
                                "no-data-template": _vm.showNomessage
                                  ? "No records available"
                                  : "",
                                "data-manager": _vm.dataManager,
                                css: _vm.css.table
                              },
                              on: {
                                "vuetable:pagination-data": _vm.onPaginationData
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "estimate_number",
                                    fn: function(props) {
                                      return [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "font-medium text-primary-500",
                                            attrs: {
                                              to: {
                                                path:
                                                  "estimates/" +
                                                  props.rowData.id +
                                                  "/view"
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  props.rowData.estimate_number
                                                ) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "total",
                                    fn: function(props) {
                                      return [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                props.rowData.total,
                                                props.rowData.user.currency
                                              )
                                            )
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "status",
                                    fn: function(props) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            style: {
                                              "background-color":
                                                "" +
                                                _vm.$utils.getBadgeStatusColor(
                                                  props.rowData.status
                                                ).bgColor,
                                              color: _vm.$utils.getBadgeStatusColor(
                                                props.rowData.status
                                              ).color
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.rowData.status.replace(
                                                    "_",
                                                    " "
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "name",
                                    fn: function(props) {
                                      return [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "avatar avatar-sm mr-2",
                                            attrs: {
                                              to: {
                                                path:
                                                  "customers/" +
                                                  props.rowData.user.id +
                                                  "/view"
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass:
                                                "avatar-img rounded-circle",
                                              attrs: {
                                                src: _vm.$utils.userImage(
                                                  props.rowData.user.avatar
                                                ),
                                                alt: "User Image"
                                              },
                                              on: { error: _vm.altImg }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                path:
                                                  "customers/" +
                                                  props.rowData.user.id +
                                                  "/view"
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(props.rowData.name))]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: _vm.$t("general.actions"),
                                    fn: function(props) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "table-button-container"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dropdown dropdown-action"
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "action-icon dropdown-toggle",
                                                    attrs: {
                                                      href: "#",
                                                      "data-toggle": "dropdown",
                                                      "aria-expanded": "false"
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-ellipsis-h"
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-menu dropdown-menu-right"
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "dropdown-item",
                                                        attrs: {
                                                          to:
                                                            "estimates/" +
                                                            props.rowData.id +
                                                            "/edit"
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "far fa-edit mr-2"
                                                        }),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "general.edit"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "dropdown-item",
                                                        attrs: {
                                                          href:
                                                            "javascript:void(0);"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeEstimate(
                                                              props.rowData.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "far fa-trash-alt mr-2"
                                                        }),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "general.delete"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "dropdown-item",
                                                        attrs: {
                                                          to:
                                                            "estimates/" +
                                                            props.rowData.id +
                                                            "/view"
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "far fa-eye mr-2"
                                                        }),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "general.view"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "dropdown-item",
                                                        attrs: {
                                                          href:
                                                            "javascript:void(0);"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.convertInToinvoice(
                                                              props.rowData.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "far fa-file-alt mr-2"
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "estimates.convert_to_invoice"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    props.rowData.status !==
                                                    "SENT"
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "dropdown-item",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0);"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.onMarkAsSent(
                                                                  props.rowData
                                                                    .id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-check-circle mr-2"
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.mark_as_sent"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    props.rowData.status !==
                                                    "SENT"
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "dropdown-item",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0);"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.sendEstimate(
                                                                  props.rowData
                                                                    .id,
                                                                  props.rowData
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-paper-plane mr-2"
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.send_estimate"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    props.rowData.status ==
                                                      "SENT" ||
                                                    props.rowData.status ==
                                                      "VIEWED"
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "dropdown-item",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0);"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.sendEstimate(
                                                                  props.rowData
                                                                    .id,
                                                                  props.rowData
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-paper-plane mr-2"
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.resend_estimate"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    props.rowData.status !==
                                                    "ACCEPTED"
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "dropdown-item",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0);"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.onMarkAsAccepted(
                                                                  props.rowData
                                                                    .id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-check-circle mr-2"
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.mark_as_accepted"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    props.rowData.status !==
                                                    "REJECTED"
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "dropdown-item",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0);"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.onMarkAsRejected(
                                                                  props.rowData
                                                                    .id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "far fa-times-circle mr-2"
                                                            }),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "estimates.mark_as_rejected"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _vm._v(" "),
                            _c("vuetable-pagination", {
                              ref: "pagination",
                              attrs: { css: _vm.css.pagination },
                              on: {
                                "vuetable-pagination:change-page":
                                  _vm.onChangePage
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("SendEstimateModal", { ref: "modal" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }