var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-app" }, [
    _c("div", { staticClass: "container" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "text", placeholder: _vm.$t("global_search.search") },
        domProps: { value: _vm.name },
        on: {
          blur: function($event) {
            _vm.toggle = false
          },
          click: function($event) {
            _vm.toggle = true
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.name = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.toggle
        ? _c("div", { staticClass: "results scrollbar" }, [
            _vm.getCustomerList.length > 0
              ? _c("span", { staticClass: "bg-gray" }, [_vm._v("CUSTOMERS")])
              : _vm._e(),
            _vm._v(" "),
            _vm.getCustomerList.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.getCustomerList, function(d) {
                    return _c(
                      "div",
                      { key: d.id, staticClass: "result ml-3 mt-1" },
                      [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.selectCustomer(d.id, 1)
                              },
                              mousedown: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v(_vm._s(d.name))]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.getUserList.length > 0
              ? _c("span", { staticClass: "bg-gray search_name" }, [
                  _vm._v("USERS")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.getUserList.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.getUserList, function(ds) {
                    return _c(
                      "div",
                      { key: ds.id, staticClass: "result ml-3 mt-1" },
                      [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.selectCustomer(ds.id, 2)
                              },
                              mousedown: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v(_vm._s(ds.name))]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _vm.getCustomerList.length === 0 && _vm.getUserList.length === 0
                ? _c("span", { staticClass: "bg-gray" }, [
                    _vm._v(" No users found")
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "btn", attrs: { type: "submit" } }, [
      _c("i", { staticClass: "fas fa-search" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }